<template>
    <div id="self-assassment">
        <div v-if="questionID == 0" class="content-wrapper intro-panel">
            <h2>Der Praxischeck Digitalisierung </h2>
            <p>Durch diesen Fragebogen können Sie den Digitalisierungsstand Ihrer Praxis ganz leicht einschätzen. Beantworten Sie einfach die folgenden elf Fragen zu den aktuellen Themen der Digitalisierung einer Praxis.</p>
            <button @click="next()" >Starten</button>
        </div>

        <div v-else-if="questionID > 0 && questionID <= questions.length" class="content-wrapper question-panel">
            <h2>Test - Frage {{ questionID }}</h2>
            <div class="answers">
                <div v-for="(question, index) in questions" class="answer-indicator" :key="index" :wert="question.answerValue"></div>
            </div>
            <div v-for="(question, index) in questions" v-show="index == questionID-1" :key="index">
                <h3>{{ question.question }}</h3>
                <div class="options-wrapper">
                    <div class="option" v-for="(option, optionIndex) in radioOptions" :key="optionIndex">
                        <label :for="`question-${index}-${optionIndex}`"> 
                            <input type="radio" :id="`question-${index}-${optionIndex}`" :name="`question-${index}-${optionIndex}`" :value="option.value" v-model="question.answerValue" />
                            {{ option.label }} 
                        </label>
                    </div>
                </div>
            </div>
            <button @click="next()" >Weiter</button>
        </div>

        <div v-else class="content-wrapper result-panel">
            <h2 class="c-full">Und das ist Ihr Ergebnis</h2>
            <h3 class="c-full">
                Vielen Dank für Ihre Teilnahme am „Praxischeck Digitalisierung“!
                <br>Lassen Sie andere daran teilhaben:
                <br><span class="hashtag">#DMEA24</span>
                <div class="social-media-wrapper c-full">
                    <div class="social-media-button instagram" @click="openURL('https://www.instagram.com/kvwl.de/')">
                        <img src="@/assets/content/img/social media/Instagram_logo_2016.svg" alt="">
                        <span>@kvwl.de</span>
                    </div>
                    <div class="social-media-button twitter" @click="openURL('https://twitter.com/KVWL_')">
                        <img src="@/assets/content/img/social media/X_logo_2023.svg" alt="">
                        <span>@KVWL</span>
                    </div>
                    <div class="social-media-button linkedin" @click="openURL('https://www.linkedin.com/company/karriere-kvwl/?originalSubdomain=de')">
                        <img src="@/assets/content/img/social media/LinkedIn_icon.svg" alt="">
                        <span>@KVWL_</span>
                    </div>
                </div>
            </h3>
            <div class="star-rating" :class="{'c-full': result.stars == 0}">
                <img v-if="result.stars && result.stars == 5" class="trophy-image" src="@/assets/content/img/trophies/trophy-5star.jpg" alt="">
                <img v-if="result.stars && result.stars == 4" class="trophy-image" src="@/assets/content/img/trophies/trophy-4star.jpg" alt="">
                <img v-if="result.stars && result.stars == 3" class="trophy-image" src="@/assets/content/img/trophies/trophy-3star.jpg" alt="">
                <img v-if="result.stars && result.stars == 2" class="trophy-image" src="@/assets/content/img/trophies/trophy-2star.jpg" alt="">
                <img v-if="result.stars && result.stars == 1" class="trophy-image" src="@/assets/content/img/trophies/trophy-1star.jpg" alt="">
                <img v-if="result.stars == 0" class="trophy-image no-trophy" src="@/assets/content/img/trophies/trophy-0star.jpg" alt="">
                <div class="stars-wrapper">
                    <div class="star" :reached="result.stars && result.stars >= 1"/>
                    <div class="star" :reached="result.stars && result.stars >= 2"/>
                    <div class="star" :reached="result.stars && result.stars >= 3"/>
                    <div class="star" :reached="result.stars && result.stars >= 4"/>
                    <div class="star" :reached="result.stars && result.stars >= 5"/>
                    </div>
                <p class="quote">Herzlichen Glückwunsch – <br> hier ist Ihr Pokal…</p>
            </div>
            <div v-if="result.stars && result.stars > 0" class="trophy-wrapper">
                <img v-if="result.stars && result.stars == 5" class="trophy-qr-code" src="@/assets/content/img/trophies/qrCode_Platz1.png" alt="">
                <img v-if="result.stars && result.stars == 4" class="trophy-qr-code" src="@/assets/content/img/trophies/qrCode_Platz2.png" alt="">
                <img v-if="result.stars && result.stars == 3" class="trophy-qr-code" src="@/assets/content/img/trophies/qrCode_Platz3.png" alt="">
                <img v-if="result.stars && result.stars == 2" class="trophy-qr-code" src="@/assets/content/img/trophies/qrCode_Platz4.png" alt="">
                <img v-if="result.stars && result.stars == 1" class="trophy-qr-code" src="@/assets/content/img/trophies/qrCode_Platz5.png" alt="">
                <p class="quote">… und hier sehen Sie das gute Stück in Augmented Reality – bestens geeignet zum Teilen und Weiterverbreiten.</p>
            </div>
            <div class="button-wrapper c-full">
                <button @click="closePanel()" >Abschließen</button>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { onMounted, Ref, ref } from 'vue';
import { Flux360InterfaceSingleton } from '@/interface/Flux360InterfaceSingleton';
import { calculateResults, getQuestions, Result } from "./SelfAssesmentUtil";
import { setActiveTrophyVideo } from "../trophyVideoManagement";

const questionID = ref(0);
const radioOptions = ref([
    {label: 'Nein', value: 0},
    {label: 'Teilweise', value: 1},
    {label: 'Ja', value: 2},
    {label: 'Kann ich nicht beantworten', value: 3},
]);
const questions = ref(getQuestions());

let result:Ref<Result> = ref({
    valuesAll: null,
    valuesCountable: null,
    amountCountables: null,
    inPercent: null,
    stars:null,
});

function next(){
    questionID.value++;
    result = ref(calculateResults());
};

function closePanel() {
    Flux360InterfaceSingleton.getInstance().getInterfaceStore().setContentPanelVisible(false);
    Flux360InterfaceSingleton.getInstance().getLifeCycleHooks().triggerOnContentPanelClosed();
    if(result.value.stars == 5) setActiveTrophyVideo(29);
    if(result.value.stars == 4) setActiveTrophyVideo(32);
    if(result.value.stars == 3) setActiveTrophyVideo(33);
    if(result.value.stars == 2) setActiveTrophyVideo(34);
    if(result.value.stars == 1) setActiveTrophyVideo(35);
}

function openURL(url: string){
    window.open(url, '_blank');
}

function cleanAnswers(){
    questions.value.forEach(question => question.answerValue = null);
}

onMounted(() => {
    cleanAnswers();
})
</script>

<style lang="scss" scoped>
#self-assassment{
    position: relative;
    display: flex;
    height:100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    *{
        user-select: text;
        ::selection{
            background: var(--color-rgb-invert);
        }
    }

    h1, h2, h3{
        margin: 0;
    }
    h3{
        margin-bottom: 16px;
        text-align: center;
        font-weight: normal;

        span.hashtag{
            font-weight: bold;
            font-style: italic;
            color: #55a51c;
        }
    }

    p{
        margin:0;
        text-align: center;

        &.quote{
            width:300px;
            opacity:1;
            line-height: 1;
            font-size: small;
            font-weight: bold;
        }

        &.hint{
            position: relative;
            font-size: small;
            font-style: normal;
            font-weight: bold;
            line-height: 1;
            text-align: left;
            padding: 1em;
            margin: 0 1em;
            background-color: #55a51c33;
            border-radius: 4px;

            &::after{
                content: "!";
                position: absolute;
                top:0;
                left:0rem;
                display: flex;
                justify-content: center;
                align-items: center;
                // font-style: italic;
                font-family: 'Times New Roman', Times, serif;
                font-size: 2em;
                font-weight: bold;
                color: #55a51c;
                transform: translate(-50%, -50%);
                background-color: white;
                height: 1.2em;
                width: 1.2em;
                text-align: center;
                border-radius: 50%;
            }

        }
    }
    
    background: 
        linear-gradient(284deg, #024f2344 40%, #024f2300 40%),
        linear-gradient(300deg, #024f2344 30%, #024f2300 30%),
        linear-gradient(135deg, #55a51c 0%, #488b18 100%); 

    button{
        clip-path: polygon(
            0 0, 
            100% 0, 
            calc(100% - 0.75rem) 100%, 
            0 100%);
        background: linear-gradient(135deg, #55a51c, #488b18);
        color: white;
        font-weight: bold;
        &:hover{
            background: linear-gradient(135deg, #017632, #024f23);
            filter:brightness(1);
        }
    }

    .content-wrapper{
        position: relative;
        display: flex;
        flex-direction: column;
        max-height: calc(100% - 48px);
        gap: 24px;
        align-items: center;
        padding: 24px;
        width: 700px;
        border-radius: 10px;
        background: white;
        
        box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.2);
        box-sizing: border-box;
        overflow-y: auto
    }

    .options-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2px;

        .option{
            position: relative;
            width: 300px;

            label {
                cursor: pointer;
                display: flex;
                gap: 8px;
                height:100%;
                width: 100%;
                padding: 4px;
                border-radius: 4px;
                box-sizing: border-box;
                border: solid 1px transparent;
                background: #55a51c11;

                &:hover{
                    background: #55a51c33;
                }
            }

            input:checked + label{
                border: solid 1px #55a51c;
            }

            
        }
    }

    .social-media-wrapper{
        display: flex;
        flex-direction: row;
        gap: 8px;
        justify-content: center;
        align-items: center;
        margin-top: 6px;

        .social-media-button{
            display: flex;
            flex-direction: row;
            gap: 4px;
            justify-content: center;
            align-items: center;
            height: 32px;
            cursor: pointer;
            background-color: rgba(0,0,0,0.1);
            font-weight: bold;
            color: white;
            padding:0 4px;
            border-radius: 4px;

            img{
                width: 20px;
            }

            span{
                font-size: small;
            }

            &.instagram{
                background-color: rgb(207,58,158);
            }
            &.twitter{
                background-color: rgb(0,0,0);
                img{
                    filter: invert(1);
                    scale: 0.8;
                }
            }
            &.linkedin{
                background-color: rgb(0,126,187);
            }
        }
    
    }
}

.answers{
    position: absolute;
    top: 0px;
    // transform: translateY(-50%);

    display: flex;
    flex-direction: row;
    gap: 2px;
    align-items: center;
    padding: 4px;
    background: #ddd;
    border-radius: 0 0 10px 10px;


    .answer-indicator{
        height:10px;
        aspect-ratio: 1/1;
        background: rgba(0,0,0,0.1);
        border-radius: 50%;

        &[wert="0"], &[wert="1"], &[wert="2"], &[wert="3"]{
            background-color: rgba(0,0,0,0.5);
        }
    }
}

.result-panel{
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    align-items: baseline;

    .c-full{
        grid-column: span 2;
    }

    .star-rating, .trophy-wrapper{
        // padding: 1em;
        box-sizing: border-box;
    }

    .star-rating{
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 6px;
        align-self: baseline;
        align-items: center;

        .trophy-image{
            width: 120px;
            margin-bottom: 1em;

            &.no-trophy{
                opacity: 0.2;
            }
        }
    
        .stars-wrapper{
            position: relative;
            display: flex;
            flex-direction: row;
            gap: 4px;
            justify-content: center;
            margin-bottom: 1em;

            .star{
                width: 32px;
                height: 32px;
                background-size: contain;
                background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23e3e3e3' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
                
                &[reached=true]{
                    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23fcd93a' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
                }
            }
        }
    
    }
    
    .trophy-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .trophy-qr-code{
            height: 200px;
            margin-bottom: 1em;
        }
    }
}

</style>