// Flux360 Logic Imports
import { Flux360LogicSingleton } from '@/logic/Flux360LogicSingleton';
import type { Situation } from "flux-360-logic/dist/src/core/Situation";
import type { SituationComponent } from "flux-360-logic/dist/src/core/situationComponents/SituationComponent";

// BABYLON Import
import { EngineStore } from '@babylonjs/core/Engines/engineStore';
import { Engine } from '@babylonjs/core/Engines/engine';
import "@babylonjs/core/Materials/standardMaterial"; // Needed for AdvancedDynamicTexture
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial';
import { VideoTexture } from '@babylonjs/core/Materials/Textures/videoTexture';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';

var trophyVideos = [
    {textureID:29,component:null},
    {textureID:32,component:null},
    {textureID:33,component:null},
    {textureID:34,component:null},
    {textureID:35,component:null},
]

var activeTrophyVideo:number;
var registrated = false;

export function RegisterTrophyVideoManagement(){

    // Workarround for Engine undefined
    Flux360LogicSingleton.getInstance().getGraphicsEngine().getLifeCycleHooks().registerOnEngineCreated((engine:Engine) => {
        EngineStore.Instances.push(engine);
    });

    Flux360LogicSingleton.getInstance().getLifeCycleHooks().registerOnAfterSituationEnter((situationID:number) => {
        console.log("Trophy Video Manager: Situation entered " + situationID);

        // Initial registration of Trophy Videos
        if(!registrated && situationID == 6){
            // Find all Trophy Videos and save them to the trophyVideos Array
            getTrophyVideoComponents();
            // set registrated true
            registrated = true;
        }


        // Dynamic Video Activation
        if(situationID == 6){
            // Show all Trophy Videos
            trophyVideos.forEach((data:any) => {
                if(data.textureID == activeTrophyVideo){
                    setVideoEnabled(data.component,true);
                } else {
                    setVideoEnabled(data.component,false);
                }
            });
        }


        // Documentation
        console.log("[Trophy Video Manager] activeTrophyVideo: ", activeTrophyVideo);
        console.log("[Trophy Video Manager] registrated: ", registrated);
        console.log("[Trophy Video Manager] Trophy Videos: ", trophyVideos);
    });
}

function getTrophyVideoComponents(){
    var situations:Situation[] = Flux360LogicSingleton.getInstance().getTour().getSituations();
    var trophySituation:Situation = situations.find((situation:Situation) => situation.getID() == 6)!;
    var trophySituationComponents = trophySituation?.getSituationComponents();
    var trophyVideoComponents = trophySituationComponents.filter((component:SituationComponent) => component.getConfig().type == "PanoramaOverlayAnimation");
    trophyVideos.forEach((data:any) => {
        data.component = trophyVideoComponents.find((component:SituationComponent) => component.getConfig().videoMediaID == data.textureID);
    });
}

function setVideoEnabled(component:SituationComponent,boolean:boolean){
    // @ts-ignore
    let rootNode = component.rootNode as TransformNode;
    var mesh = rootNode.getChildren()[0] as Mesh;
    mesh.renderingGroupId = 1;
    var material = mesh.material as StandardMaterial;
    var videoTexture1 = material.diffuseTexture as VideoTexture;

    if(boolean){
        rootNode.setEnabled(true);
        videoTexture1.video.play();
    } else {
        rootNode.setEnabled(false);
        videoTexture1.video.pause();
    }
    console.log("[Trophy Video Manager] " + (boolean ? "Enable" : "Disable") + " Trophy Video", component);
}

export function setActiveTrophyVideo(textureID:number){
    activeTrophyVideo = textureID;
    trophyVideos.forEach((data:any) => {
        setVideoEnabled(data.component,false);
    });
    console.log("[Trophy Video Manager] Set active Trophy Video to " + textureID);
    var component = trophyVideos.find((data:any) => data.textureID == textureID)!.component;
    if(component) setVideoEnabled(component,true);
}