import questionsJSON from '@/assets/content/sa-questions.json';

export interface Question {
    id: number;
    question: string;
    answerValue: number | null;
}
export interface Result{
    valuesAll: number[] | null;
    valuesCountable: number[] | null;
    amountCountables: number | null;
    inPercent: number | null;
    stars: number | null;
}

export function calculateResults(){
    var answers = getQuestions().map(question => question.answerValue!);
    var countables = answers.filter(answer => answer != 3 && answer != null);
    var amountCountables = countables.length;
    var sumCountables = countables.reduce((a,b) => a!+b!, 0);
    var inPercent = sumCountables! / (amountCountables * 2) * 100;
    // 0-24% = 1 Star
    // 25-49% = 2 Stars
    // 50-74% = 3 Stars
    // 75-99% = 4 Stars
    // 100% = 5 Stars
    var stars = inPercent < 25 ? 1 : inPercent < 50 ? 2 : inPercent < 75 ? 3 : inPercent < 100 ? 4 : 5;

    return {
        valuesAll: answers,
        valuesCountable: countables,
        amountCountables: amountCountables,
        inPercent: Number(inPercent.toFixed(2)),
        stars: stars,
    } as Result;
}

export function getQuestions(){
    return questionsJSON as Question[]
}