<template>
    <div id="DigitaleAnamnese">

        <transition name="fade">
            <PraxisInterface v-if="[1,2,3,4,6].includes(stepID)">
                <transition name="fade">
                    <Patientenlist v-if="[1].includes(stepID)" :nextStep="nextStep"/>
                    <Patientenprofil v-else-if="[2,3,4].includes(stepID)" :state="0" :nextStep="nextStep" @setStep="setStep"/>
                    <Patientenprofil v-else-if="[6].includes(stepID)" :state="1" :nextStep="nextStep" @setStep="setStep"/>
                </transition>
            </PraxisInterface>
        </transition>

        <transition name="fade">
            <Questionairies v-if="stepID == 3" :state="0" :nextStep="nextStep"/>
            <Questionairies v-else-if="stepID == 4" :state="1" :nextStep="nextStep"/>
        </transition>

        <transition name="fade">
            <Quest v-if="stepID == 1" :state="0" :nextStep="nextStep"/>
            <Quest v-else-if="stepID == 2" :state="1" :nextStep="nextStep"/>
            <Quest v-else-if="stepID == 3" :state="2" :nextStep="nextStep"/>
            <Quest v-else-if="stepID == 4" :state="3" :nextStep="nextStep"/>
            <Quest v-else-if="stepID == 6" :state="4" :nextStep="nextStep"/>
        </transition>

        <transition name="fade">
            <CommentaryScreen v-if="stepID == 0" :state="0" :nextStep="nextStep"/>
            <CommentaryScreen v-else-if="stepID == 5" :state="1" :nextStep="nextStep"/>
            <CommentaryScreen v-else-if="stepID == 7" :state="2" :nextStep="nextStep"/>
        </transition>

        <transition>
            <WaitingOverlay v-if="showClock"/>
        </transition>

        <!-- Debugger -->
        <!-- <div class="switcher">
            <input type="number" v-model="stepID" name="stepID" id="stepID">
        </div> -->
    </div>
</template>

<script setup lang="ts">
import { onMounted, provide, ref, watch } from 'vue'
import PraxisInterface from './components/Praxisinterface/PraxisInterface.vue';
import Patientenlist from './components/Praxisinterface/components/Patientenlist.vue';
import Patientenprofil from './components/Praxisinterface/components/Patientenprofil.vue';
import Questionairies from "./components/Praxisinterface/Questionairies.vue";
import Quest from './components/Quest.vue';
import CommentaryScreen from './components/CommentaryScreen/CommentaryScreen.vue';
import WaitingOverlay from './components/WaitingOverlay.vue';
import { Flux360InterfaceSingleton } from '@/interface/Flux360InterfaceSingleton';

var stepID = ref(0);

function nextStep(){
    stepID.value++;
}

function setStep(id:number){
    stepID.value = id;
}

var showClock = ref(false);

watch(stepID,()=>{
    if(stepID.value>7) stepID.value=0;
    if(stepID.value<0) stepID.value=7;

    if(stepID.value == 6){
        showClock.value = true;
        setTimeout(() => {
            showClock.value = false;
        }, 3000);
    }
})

Flux360InterfaceSingleton.getInstance().getLifeCycleHooks().registerOnContentPanelClosed(()=>{
    stepID.value = 0;
})
</script>

<style lang="scss" scoped>
#DigitaleAnamnese{
    position: relative;
    height:100%;
    width:100%;
}

.switcher{
    position: absolute;
    bottom:0;
    right:0;

    input[type=number]{
        width: 48px;
        font-size: 2em;
    }
}
</style>

<style lang="scss">
:root{
    --color-rgb: rgb(85, 165, 28);
    --color-rgb-invert: rgb(225, 127, 40);
}
.fade-enter-from,
.fade-leave-to{
    opacity:0;
}
</style>
