import { Image as GUIImage } from "@babylonjs/gui/2D/controls/image";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";
import { TextBlock } from '@babylonjs/gui/2D/controls/textBlock';
import { StackPanel } from '@babylonjs/gui/2D/controls/stackPanel';
import { Button } from '@babylonjs/gui/2D/controls/button';
import { WebXRHandJoint } from "@babylonjs/core/XR/features/WebXRHandTracking";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { ScrollViewer } from "@babylonjs/gui/2D/controls/scrollViewers/scrollViewer";
import { Grid } from "@babylonjs/gui/2D/controls/grid";
import { Control } from "@babylonjs/gui/2D/controls/control";

import { CustomDialogueHandler } from "../CustomVRDialogue";

const pageData = [
    {
        img:"/src/assets/vr/img/DigitaleAnamnese/digitaleAnamnese-1.jpg",
        eventArea:{
            height: "9.7%", width: "18.1%",
            top: "31.1%", left: "0%"
        }
    },
    {
        img:"/src/assets/vr/img/DigitaleAnamnese/digitaleAnamnese-2.jpg",
        eventArea:{
            height: "19.8%", width: "71.46%",
            top: "28.55%", left: "14%"
        }
    },
    {
        img:"/src/assets/vr/img/DigitaleAnamnese/digitaleAnamnese-3.jpg",
        eventArea:{
            height: "11%", width: "25.55%",
            top: "1.6%", left: "22.4%"
        }
    },
    {
        img:"/src/assets/vr/img/DigitaleAnamnese/digitaleAnamnese-4.jpg",
        eventArea:{
            height: "7.5%", width: "44.6%",
            top: "-31.2%", left: "-16.9%"
        }
    },
    {
        img:"/src/assets/vr/img/DigitaleAnamnese/digitaleAnamnese-5.jpg",
        eventArea:{
            height: "10.1%", width: "12.5%",
            top: "43.2%", left: "37.7%"
        }
    },
    {
        img:"/src/assets/vr/img/DigitaleAnamnese/digitaleAnamnese-6.jpg",
        eventArea:{
            height: "74%", width: "59.4%",
            top: "-1%", left: "14.65%"
        }
    },
    {
        img:"/src/assets/vr/img/DigitaleAnamnese/digitaleAnamnese-7.jpg",
        eventArea:{
            height: "10.4%", width: "13.2%",
            top: "43%", left: "37.3%"
        }
    },
    {
        img:null,
        eventArea:null,
        custom:{
            title:"Vorteile in der Übersicht",
            description:"Das ging doch ziemlich einfach von der Hand, oder? Deshalb wollen wir hier mal einige Vorteile sammeln, warum die Digitalisierung der Anamnese einen großen Mehrwert für Arztpraxen bietet:",
            button:{
                label:"Weiter",
                action:"next"
            },
            benefitcards:[
                {label:"Schnellere Vorbereitung der Unterlagen",text:"Die Unterlagen müssen nicht mehr ausgedruckt, auf Klemmbretter verteilt und anderweit vorbereitet werden.",img:'update-icon.svg'},
                {label:"Keine Wartezeit",text:"Patient*innen müssen nicht mehr auf die Vorbereitung der Unterlagen und Sie nicht mehr auf das Ausfüllen in der Praxis warten.",img:'hourglass_disabled-icon.svg'},
                {label:"Weniger Rückfragen",text:"Bei inhaltlichen Fragen des Patient*innenen können durch digitale Hilfen beantwortet werden.",img:'message-circle-question-svgrepo-com.svg'}
            ]
        }
    },
    {
        img:"/src/assets/vr/img/DigitaleAnamnese/digitaleAnamnese-9.jpg",
        eventArea: null,
        timer: 3000
    },
    {
        img:"/src/assets/vr/img/DigitaleAnamnese/digitaleAnamnese-10.jpg",
        eventArea:{
            height: "13.75%", width: "39.45%",
            top: "-41.85%", left: "29.3%"
        }
    },
    {
        img:null,
        eventArea:null,
        custom:{
            title:"Vorteile in der Übersicht",
            description:"Das ging doch ziemlich einfach von der Hand, oder? Deshalb wollen wir hier mal einige Vorteile sammeln, warum die Digitalisierung der Anamnese einen großen Mehrwert für Arztpraxen bietet:",
            button:{
                label:"Beenden",
                action:"close"
            },
            benefitcards:[                
                {label:"Direkte Verfügbarkeit​",text:"Der Fragebogen ist in Echtzeit in Ihrem System verfügbar. Zusätzliches Plus: alle Daten sind lesbar – nichts ist verschmiert oder unleserlich. Oft werden auch wichtige Informationen direkt in den Karteieintrag übernommen.​",img:'wifi-icon.svg'},
                {label:"Automatische Zuordnung",text:"Der Fragebogen wird automatisch der richtigen Patientenkartei zugeordnet – eine Fehlerquelle weniger!​",img:'auto_transmission-icon.svg'},
                {label:"Kein zusätzlicher Weg für Patient*innen",text:"Patien*innen müssen nicht mehr extra für die Abgabe des Bogens zur Praxis kommen.\n\n Dies bietet insbesondere immobilen Patient*innen Erleichterung.",img:'car-side-svgrepo-com.svg'},
                {label:"Bessere Datenqualität​",text:"Der Bogen kann zeit- und ortsunabhängige ausgefüllt werden. Dadurch können betreuende Personen unterstützen oder Infos noch einmal zu Hause nachgeschlagen werden.",img:'inventory_checkmark-icon.svg'},
                {label:"Mehr Zeit für Patient*innen​",text:"Durch schlankere administrative Prozesse hat das Praxisteam mehr Zeit  für die eigentliche Patientenversorgung.​",img:'more_time-icon.svg'}
            ]
        }
    }
];

var currentPage = 0;

var guiBase:Rectangle;

export function constructDigitaleAnamneseContent(rect:Rectangle, vrDialogue:CustomDialogueHandler){
    console.log("construct Digitale Anamnese Content");
    guiBase = rect;
    currentPage = 0; // remove if you want to continue from last page
    constructPage(vrDialogue);
}

function constructPage(vrDialogue:CustomDialogueHandler){
    // Create Background
    if(pageData[currentPage].img && pageData[currentPage].img != null){
        guiBase.addControl(constructImageBackground(pageData[currentPage].img!));
    } else {
        guiBase.addControl(constructColorBackground("#53A01B"));
    }

    // Create Event Area
    if(pageData[currentPage].eventArea && pageData[currentPage].eventArea != null){
        var eventArea = constructEventArea(
            pageData[currentPage].eventArea!.height, 
            pageData[currentPage].eventArea!.width, 
            pageData[currentPage].eventArea!.top, 
            pageData[currentPage].eventArea!.left,
            vrDialogue
        );
        guiBase.addControl(eventArea);
    }

    // Create Timer
    if(pageData[currentPage].timer){
        setTimeout(() => {
            nextPage(vrDialogue);
        }, pageData[currentPage].timer);
    }

    // Create Custom Dialogue
    if(pageData[currentPage].custom){
        let custom = pageData[currentPage].custom!;
        constructCustomDialogue(custom, vrDialogue);
    }

}

function nextPage(vrDialogue:CustomDialogueHandler){
    guiBase!.children.forEach(child => { 
        setTimeout(() => { 
            child.dispose(); 
        }, 0); 
    }); 
    currentPage++;
    if(currentPage < pageData.length){
        constructPage(vrDialogue);
    }
}

function closePanel(){
    guiBase!.children.forEach(child => { 
        setTimeout(() => { 
            child.dispose(); 
        }, 0); 
    });
}

// Component Constructors
function constructCustomDialogue(custom:any, vrDialogue:CustomDialogueHandler){
    let scrollpanel = new ScrollViewer("ScrollContainer");
    scrollpanel.barSize = 15;
    scrollpanel.scrollBackground = "#F1F1F1";
    guiBase.addControl(scrollpanel);

    // Custom Scroll behavior with hand tracking
    let isTouching = false;
    let initialTouchPosition = new Vector3(0,0,0);
    let f = () => {
        let rightHand = vrDialogue.getRightHand();
        let fingerCollider = vrDialogue.getFingerCollider();
        let plane = vrDialogue.getPlane();
        if(rightHand && fingerCollider && plane){
            let indexFinger = rightHand.getJointMesh(WebXRHandJoint.INDEX_FINGER_TIP);

            if(fingerCollider.intersectsMesh(plane, true)){
                if(!isTouching){
                    initialTouchPosition = indexFinger.position.clone();
                    isTouching = true;
                }
            }else{
                if(isTouching){
                    isTouching = false;
                }
            }

            if(isTouching){
                let distance = indexFinger.position.y - initialTouchPosition.y;
                let scrollAmmount = distance * 10; // scrollammount in relation to the height of the plane
                initialTouchPosition = indexFinger.position.clone();
                scrollpanel.verticalBar.value += scrollAmmount;
            }
        }
    }   
    scrollpanel.onDisposeObservable.add(() => {
        scrollpanel.host.getScene()!.unregisterBeforeRender(f);
    });
    scrollpanel.host.getScene()!.registerBeforeRender(f);

    let wrapper = new StackPanel("Wrapper");
    wrapper.paddingBottom = "40px";
    wrapper.onBeforeDrawObservable.add(() => {
        scrollpanel.thumbLength = 500 / wrapper.heightInPixels; // Adjust size of scrollbar to match content length
    })
    scrollpanel.addControl(wrapper);

    let headline = new TextBlock("Headline", custom.title);
    headline.fontFamily = "OpenSansRegular";
    headline.fontWeight = "bold";
    headline.fontSize = "40px";
    headline.color = "white";
    headline.resizeToFit = true;
    headline.textWrapping = true;
    headline.paddingTop = "64px";
    headline.paddingBottom = "48px";
    wrapper.addControl(headline);

    let textContainer = new Rectangle("TextContainer");
    textContainer.width = "80%";
    textContainer.adaptHeightToChildren = true;
    textContainer.background = "#ffffff";
    textContainer.paddingBottom = "40px";
    textContainer.cornerRadius = 8;
    textContainer.thickness = 0;
    wrapper.addControl(textContainer);

    let textStackPanel = new StackPanel("TextStackPanel");
    textStackPanel.paddingBottom = textStackPanel.paddingTop = textStackPanel.paddingLeft = textStackPanel.paddingRight = "20px";
    textContainer.addControl(textStackPanel);

    let text = new TextBlock("Description", custom.description);
    text.fontFamily = "OpenSansRegular";
    text.fontSize = "24px";
    text.resizeToFit = true;
    text.textWrapping = true;
    text.paddingBottom = "20px";
    textStackPanel.addControl(text);

    if(custom.button){
        textStackPanel.addControl(constructButton(custom.button.label, custom.button.action, vrDialogue));
    }

    var rowCount = Math.ceil(custom.benefitcards.length/3);
    var cardHeightInPixel = 500;
    var cardWidthInPixel = 260;

    let cardGrid = new Grid("CardWrapper");
    cardGrid.height = rowCount * (cardHeightInPixel + 20) + "px";
    cardGrid.width =  3 * (cardWidthInPixel + 20) + "px";

    for(var i = 0; i < rowCount; i++){
        cardGrid.addRowDefinition(1/rowCount);
    }

    cardGrid.addColumnDefinition(0.33);
    cardGrid.addColumnDefinition(0.33);
    cardGrid.addColumnDefinition(0.33);
    wrapper.addControl(cardGrid);

    custom.benefitcards.forEach((card:any, index:number) => {
        let cardWrapper = new Rectangle("CardWrapper");
        cardWrapper.height = cardHeightInPixel + "px";
        cardWrapper.width = cardWidthInPixel + "px";
        cardWrapper.background = "#ffffff";
        cardWrapper.cornerRadius = 8;
        cardWrapper.thickness = 0;
        cardGrid.addControl(cardWrapper,index%rowCount,index%3);

        let paddingRectangle = new Rectangle("Padding");
        paddingRectangle.adaptHeightToChildren = true;
        paddingRectangle.adaptHeightToChildren = true;
        paddingRectangle.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
        paddingRectangle.setPadding("20px");
        paddingRectangle.thickness = 0;
        cardWrapper.addControl(paddingRectangle);

        let stackpanel = new StackPanel("CardContainer");
        paddingRectangle.addControl(stackpanel);

        let cardLabel = new TextBlock("CardLabel", card.label);
        cardLabel.fontFamily = "OpenSansRegular";
        cardLabel.fontSize = "28px";
        cardLabel.fontWeight = "bold";
        cardLabel.resizeToFit = true;
        cardLabel.textWrapping = true;
        cardLabel.paddingBottom = "20px";
        stackpanel.addControl(cardLabel);

        let cardText = new TextBlock("CardText", card.text);
        cardText.fontFamily = "OpenSansRegular";
        cardText.fontSize = "20px";
        cardText.resizeToFit = true;
        cardText.textWrapping = true;
        stackpanel.addControl(cardText);

        let cardImage = new GUIImage("CardImage", "/src/assets/vr/img/" + card.img);
        cardImage.width = "50px";
        cardImage.height = "50px";
        stackpanel.addControl(cardImage);
    });
}

function constructImageBackground(src:string){
    let screen = new GUIImage("Screen", src);
    return screen;
}

function constructColorBackground(color:string){
    let background = new Rectangle("Background");
    background.background = color;
    background.thickness = 0;
    return background;
}

function constructEventArea(height:string, width:string, top:string, left:string, vrDialogue:CustomDialogueHandler){
    let eventArea = new Rectangle("EventArea");
    eventArea.width = width;
    eventArea.height = height;
    eventArea.top = top;
    eventArea.left = left;
    eventArea.thickness = 0;
    // eventArea.background = "rgba(0,0,0,0.3)"; // Debug
    
    eventArea.onPointerClickObservable.add(() => {
        nextPage(vrDialogue);
    });
    
    return eventArea;
}

function constructButton(label:string, actionType:string, vrDialogue:CustomDialogueHandler){
    let button = Button.CreateSimpleButton("Button", label);
    button.fontSize= "24px";
    button.width = "180px";
    button.height = "64px";
    button.color = "white";
    button.fontWeight = "bold";
    button.background = "#53A01B";

    button.onPointerClickObservable.add(() => {
        if(actionType == "next"){
            nextPage(vrDialogue);
        }
        if(actionType == "close"){
            closePanel();
        }
    });
    return button;
}
