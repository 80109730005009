// Flux360 Logic Imports
import { Flux360LogicSingleton } from '@/logic/Flux360LogicSingleton';
import type { Situation } from "flux-360-logic/dist/src/core/Situation";

// BABYLON Import
import { EngineStore } from '@babylonjs/core/Engines/engineStore';
import { Engine } from '@babylonjs/core/Engines/engine';
import { Scene } from '@babylonjs/core/scene';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import "@babylonjs/core/Materials/standardMaterial"; // Needed for AdvancedDynamicTexture
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { AdvancedDynamicTexture } from "@babylonjs/gui/2D/advancedDynamicTexture";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { TextBlock } from "@babylonjs/gui/2D/controls/textBlock";
import { StackPanel } from '@babylonjs/gui/2D/controls/stackPanel';


type Location = {
    situationID:number,
    position:Vector3,
    rotation:Vector3,
    scaling:Vector3
}

var locations:Location[] = [
    { 
        situationID: 1,
        position: new Vector3(0, 75, 168), 
        rotation: new Vector3(0, 0, 0), 
        scaling: new Vector3(1.75, 1.75, 1.75),
    },
    { 
        situationID: 2,
        position: new Vector3(-170, 31, 71), 
        rotation: new Vector3(0, 0, 0),
        scaling: new Vector3(0.75, 0.75, 0.75),
    },
    { 
        situationID: 3,
        position: new Vector3(-178, 16.4, 40), 
        rotation: new Vector3(0, 0, 0),
        scaling: new Vector3(0.4, 0.4, 0.4),
    },
]; 

var greeting = "Herzlich willkommen";
var greeting_sub = "in der virtuellen dipraxis!";

var scene:null|Scene = null;

export function RegisterInteractiveTextWall(){
    // Workarround for Engine undefined
    Flux360LogicSingleton.getInstance().getGraphicsEngine().getLifeCycleHooks().registerOnEngineCreated((engine:Engine) => {
        EngineStore.Instances.push(engine);
    });


    Flux360LogicSingleton.getInstance().getLifeCycleHooks().registerOnAfterSetup(() => {
        scene = Flux360LogicSingleton.getInstance().getGraphicsEngine().getScene();
        
        Flux360LogicSingleton.getInstance().getLifeCycleHooks().registerOnAfterSituationEnter( (situationID:number) => {
            var activeSituationID = Flux360LogicSingleton.getInstance().getTour().getActiveSituationID();
            var activeSituation:Situation = Flux360LogicSingleton.getInstance().getTour().getSituations().find((situation:Situation) => situation.getID() == activeSituationID); 

            var locationData = locations.find((location) => location.situationID == situationID);
    
            if(locationData != undefined){
                var activeSituationNodeName = activeSituation?.getName();
                var activeSituationNode = scene!.getTransformNodeByName(activeSituationNodeName);
                if(activeSituationNode && activeSituationNode.getChildren() && activeSituationNode.getChildren().findIndex(tn => tn.name=="TextWall") == -1){
                    createTextWall(locationData,activeSituation);
                }
            }
        });
    });
};

function createTextWall(locationData:Location,activeSituation:Situation){
    // Create a parent node for the text wall 
    let parentNode = new TransformNode("TextWall", scene!);
    parentNode.position = locationData.position;
    parentNode.rotation = new Vector3(locationData.rotation.x / 180 * Math.PI, locationData.rotation.y / 180 * Math.PI, locationData.rotation.z / 180 * Math.PI);;
    parentNode.scaling = locationData.scaling;

    // Relocate to Textwall under the situationNode 

    parentNode.parent = scene!.getTransformNodeByName(activeSituation.getName());

    var greetingTextNode = createGreetingText();
    greetingTextNode.parent = parentNode;

    var situationListNode = createSituationList(locationData,activeSituation);
    situationListNode.parent = parentNode;

}

function createGreetingText(){
    // Create the Mesh-Plane for the TextWall Gui
    var plane = MeshBuilder.CreatePlane("GreetingText_Plane", {size: 100, sideOrientation: Mesh.DOUBLESIDE}, scene!);
    plane.position = new Vector3(-40, 20, 0);
    plane.renderingGroupId = 1;
    plane.isPickable = false;

    var advancedTexture = AdvancedDynamicTexture.CreateForMesh(plane);

    var wrapper = new StackPanel("Greeting_Wrapper");
    advancedTexture.addControl(wrapper);

    var text1 = new TextBlock("Textblock", greeting);
    text1.fontSize = 80;
    text1.color = "rgba(0,0,0,0.5)";
    text1.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    text1.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    text1.resizeToFit = true;
    wrapper.addControl(text1);

    var text2 = new TextBlock("Textblock", greeting_sub);
    text2.fontSize = 50;
    text2.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    text2.resizeToFit = true;
    wrapper.addControl(text2);

    return plane;
}

function createSituationList(locationData:Location,activeSituation:Situation){
    // Create the Mesh-Plane for the TextWall Gui
    var plane = MeshBuilder.CreatePlane("SituationList_Plane", {size: 100, sideOrientation: Mesh.DOUBLESIDE}, scene!);
    plane.renderingGroupId = 1;
    plane.position = new Vector3(40, -20, 0);

    var advancedTexture = AdvancedDynamicTexture.CreateForMesh(plane);

    var wrapper = new StackPanel("Greeting_Wrapper");
    wrapper.paddingBottom = wrapper.paddingTop = "20px";
    wrapper.paddingLeft = wrapper.paddingRight = "20px";
    advancedTexture.addControl(wrapper);

    Flux360LogicSingleton.getInstance().getTour().getSituations().forEach((situation:Situation) => {
        wrapper.addControl(createSituationButton(situation.getName(), situation.getID(), activeSituation));
    });

    return plane;
}

function createSituationButton(situationName:string, situationID:number, activeSituation:Situation){
    var button = new Rectangle("Button_"+situationID);
    button.linkOffsetY = -5;
    button.width = "80%";
    button.height = "75px";
    button.thickness = 0;
    button.hoverCursor = "pointer";

    var button_bg = new Rectangle("ButtonBackground_"+situationID);
    button_bg.width = "100%";
    button_bg.height = "100%";
    button_bg.cornerRadius = 20;
    button_bg.thickness = 0;
    button.addControl(button_bg);


    var title = new TextBlock("Buttontext_"+situationID, situationName);
    title.color = "rgba(0,0,0,0.8)";
    title.paddingBottom = title.paddingTop = "0px";
    title.paddingLeft = title.paddingRight = "20px";
    title.fontSize = 30;
    title.resizeToFit = true;
    title.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    button.addControl(title);



    button.onPointerEnterObservable.add(() => {
        button.scaleX = 1.1;
        button.scaleY = 1.1;
        button_bg.background = "rgba(255,255,255,0.1)";
    });
    button.onPointerOutObservable.add(() => {
        button.scaleX = 1;
        button.scaleY = 1;
        button_bg.background = "rgba(255,255,255,0)";
    });
    button.onPointerDownObservable.add(() => {
        activeSituation.getSituationManager().changeSituation(situationID, {playAnimation: false});
    });

    return button;
}