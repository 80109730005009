<template>
    <div class="quest-card">
        <div class="quest-wrapper" :class="{'minimized':isEnd}">
            <div class="main-quest">
                Bei Frau <span class="highlight">Erika Mustermann</span> fehlen noch Patienteninformationen.
                Schicken Sie der Patientin den <span class="highlight">Aufnahmefragebogen</span>, um die fehlenden Daten zu ergänzen.
            </div>
            <ul>
                <li v-if="props.state! >= 0" :class="{'done':props.state!>0}">Klicken Sie auf das Profil der Patientin</li>
                <li v-if="props.state! >= 1" :class="{'done':props.state!>1}">Klicken Sie auf <span class="highlight">Fragebogen schicken</span></li>
                <li v-if="props.state! >= 2" :class="{'done':props.state!>2}">Wählen Sie <span class="highlight">Dipraxis: Aufnahmebogen</span> aus und klicken anschließend auf <span class="highlight">Weiter</span></li>
                <li v-if="props.state! >= 3" :class="{'done':props.state!>3}">Füllen Sie die das Formular nach belieben aus und klicken anschließend auf <span class="highlight">Verschicken</span></li>
                <li v-if="props.state! == 4" :class="{'done':isEnd}">Warten Sie auf die Rückmeldung</li>
            </ul>
        </div>
        <div v-if="isEnd" class="quest-card-end">
            <span class="highlight">Geschafft!</span>
            <button @click="nextStep!">Fertig</button>
        </div>
    </div>
</template>

<script setup lang="ts">
import {onMounted, ref, watch} from 'vue';

const props = defineProps({
    state:{
        type: [Number],
        default:0, 
        required: true
    },
    nextStep:{
        type:Function,
        required: true
    }
})

function next(){
    console.log(1)
    props.nextStep!;
}

onMounted(() => {
    if(props.state == 4){
        setTimeout(() => {
            isEnd.value = true;
        }, 5000);
    } else {
        isEnd.value = false;
    }
})


const isEnd = ref(false);
</script>

<style lang="scss">
.quest-card{
    position:absolute;
    right: 8px;
    top:8px;
    background-color: rgba(30,30,30,0.8);
    color: white;
    font-size: 0.9em;
    box-shadow: 0px 8px 16px rgba(0,0,0,0.4);
    overflow:hidden;
    transition: all 1s ease-out;
    max-height: 1000px;
    border-radius: 4px;

    .minimized{
        max-height: 0px;
        padding-top:0 !important;
        padding-bottom:0 !important;
    }

    span.highlight{
        font-weight: bold;
        color: var(--color-rgb);
    }

    .quest-wrapper{
        padding: 4px 8px;
        max-width: 300px;
        overflow:hidden;
        transition-delay: 1000ms;
        transition: max-width 300ms ease-out, padding 300ms ease-out;
    }

    .main-quest{
        padding-bottom: 8px;
        border-bottom: 1px solid rgba(255,255,255,0.2);
    }

    ul{
        list-style: decimal;
        padding-left: 24px;
        margin: 8px 0px;
        li{
            font-weight: bold;

            &::marker{
                color:white;
                font-size: 1em;
            }

            &.done{
                text-decoration: line-through;
                opacity: 0.3;
            }
        }
    }

    .quest-card-end{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
        padding: 12px;

        span{
            font-weight: bold;
        }
    }
}
</style>