<template>
        <div class="cd-accordion-section">

            <!-- accordion header -->
            <div ref="header" @click="openAccordion($event)" class="cd-accordion-header">
                <!-- icon -->
                <div>
                    <img :src="`src/assets/content/img/` + accordionData.icon" class="cd-accordion-icon">
                </div>
            
                <!-- title -->
                <h3 class="cd-header-title"> 
                    <div>{{ accordionData.title }}</div>
                </h3>

                <!-- icon drop down -->
                <div class="cd-accordion-icon-right">
                    <img v-if="isOpen" :src="`src/assets/content/img/arrow_drop_down_circle_closed.svg`" class="cd-accordion-icon-right-img">
                    <img v-if="!isOpen" :src="`src/assets/content/img/arrow_drop_down_circle_opened.svg`" class="cd-accordion-icon-right-img">
                </div>
            </div>

            <!-- accordion content -->
            <div v-if="isOpen" class="cd-accordion-content">
                <PopupTypes v-for="content in accordionData.content" :content="content"/>
            </div>
        </div>
</template>

<script setup lang="ts">
import { PropType, ref } from 'vue';
import PopupTypes from './PopupTypes.vue';

let isOpen = ref(false);

defineProps({
    accordionData: {
        type: Object as PropType<Accordion>,
        required: true
    }
})

function openAccordion(event:MouseEvent){
    isOpen.value = !isOpen.value;

    if(isOpen.value){
        setTimeout(() => {
            if(event.target instanceof Element){ 
                event.target.scrollIntoView({
                    behavior: 'smooth'
                });   
            }
        }, 0);
    }
}
</script>