<template>
    <div id="commentary-screen">
        <div class="cs-title-wrapper">
            <!-- Title of the Screen -->
            <h2 v-if="state == 0">Digitale Anamnese</h2>
            <h2 v-if="state == 1">Vorteile in der Übersicht</h2>
            <h2 v-if="state == 2">Vorteile in der Übersicht</h2>
        </div>
        <div class="cs-textbox-wrapper">
            <!-- main Commentary -->
            <div class="cs-textbox-body">
                <p v-if="state == 0">
                    In dieser Tour werden wir Ihnen zeigen, warum die digitale Erfassung von Patientendaten effizienter ist als der traditionelle Papierweg. 
                    Wir führen sie anhand einer Checkliste und zeigen ihnen, wie einfach und zeitsparend dieser Prozess ist. Lassen Sie uns beginnen und die Vorzüge der digitalen Anamnese entdecken!
                </p>
                <p v-if="state == 0"> 
                    Für ein immersiveres Gefühl haben wir dafür ein Beispielszenario gewählt:<br>
                    <strong>Bei Frau <span class="highlight">Erika Mustermann</span> fehlen noch Patienteninformationen.<br>
                    Schicken Sie der Patientin den <span class="highlight">Aufnahmefragebogen</span>, um die fehlenden Daten zu ergänzen.</strong>
                </p>
                <p v-if="state == 1">
                    Das ging doch ziemlich einfach von der Hand, oder? Deshalb wollen wir hier mal einige Vorteile sammeln, warum die Digitalisierung der Anamnese einen großen Mehrwert für Arztpraxen bietet:
                </p>

                <p v-if="state == 2">
                    Das ging doch ziemlich einfach von der Hand, oder? Deshalb wollen wir hier mal einige Vorteile sammeln, warum die Digitalisierung der Anamnese einen großen Mehrwert für Arztpraxen bietet:
                </p>
            </div>

            <!-- Buttons -->
            <div class="cs-textbox-buttons">
                <button v-if="state == 0" @click="nextStep!">Starten</button>
                <button v-if="state == 1" @click="nextStep!">Weiter</button>
                <button v-if="state == 2" @click="closePanel()">Beenden</button>
            </div>
        </div>
        <div v-if="state != 0" class="cs-benefit-list">
            <BenefitCard v-if="state == 1" v-for="benefit in benefitsS1" :benefit="benefit" :key="benefit.label"/>
            <BenefitCard v-if="state == 2" v-for="benefit in benefitsS2" :benefit="benefit" :key="benefit.label"/>
        </div>
    </div>
</template>

<script setup lang="ts">
import BenefitCard from './components/BenefitCard.vue';
import { ref } from 'vue';
import { Flux360InterfaceSingleton } from '@/interface/Flux360InterfaceSingleton';

const props = defineProps({
    state:{
        type:Number,
        required: true
    },
    nextStep:{
        type:Function,
        required: true
    }
})

const benefitsS1 = ref([
    {label:"Schnellere Vorbereitung der Unterlagen",text:"Die Unterlagen müssen nicht mehr ausgedruckt, auf Klemmbretter verteilt und anderweit vorbereitet werden.",img:'update-icon.svg'},
    {label:"Keine Wartezeit",text:"Patient*innen müssen nicht mehr auf die Vorbereitung der Unterlagen und Sie nicht mehr auf das Ausfüllen in der Praxis warten.",img:'hourglass_disabled-icon.svg'},
    {label:"Weniger Rückfragen",text:"Bei inhaltlichen Fragen des Patient*innenen können durch digitale Hilfen beantwortet werden.",img:'message-circle-question-svgrepo-com.svg'}
])
const benefitsS2 = ref([
    {label:"Direkte Verfügbarkeit​",text:"Der Fragebogen ist in Echtzeit in Ihrem System verfügbar. Zusätzliches Plus: alle Daten sind lesbar – nichts ist verschmiert oder unleserlich. Oft werden auch wichtige Informationen direkt in den Karteieintrag übernommen.​",img:'wifi-icon.svg'},
    {label:"Automatische Zuordnung",text:"Der Fragebogen wird automatisch der richtigen Patientenkartei zugeordnet – eine Fehlerquelle weniger!​",img:'auto_transmission-icon.svg'},
    {label:"Kein zusätzlicher Weg für Patient*innen",text:"Patien*innen müssen nicht mehr extra für die Abgabe des Bogens zur Praxis kommen.\n\n Dies bietet insbesondere immobilen Patient*innen Erleichterung.",img:'car-side-svgrepo-com.svg'},
    {label:"Bessere Datenqualität​",text:"Der Bogen kann zeit- und ortsunabhängige ausgefüllt werden. Dadurch können betreuende Personen unterstützen oder Infos noch einmal zu Hause nachgeschlagen werden.",img:'inventory_checkmark-icon.svg'},
    {label:"Mehr Zeit für Patient*innen​",text:"Durch schlankere administrative Prozesse hat das Praxisteam mehr Zeit  für die eigentliche Patientenversorgung.​",img:'more_time-icon.svg'}
])



    

function closePanel() {
    Flux360InterfaceSingleton.getInstance().getInterfaceStore().setContentPanelVisible(false);
    Flux360InterfaceSingleton.getInstance().getLifeCycleHooks().triggerOnContentPanelClosed();
}
</script>

<style lang="scss">
#commentary-screen{
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
    background: 
        linear-gradient(284deg, #024f2344 40%, #024f2300 40%),
        linear-gradient(300deg, #024f2344 30%, #024f2300 30%),
        linear-gradient(135deg, #55a51c 0%, #488b18 100%); 

    padding: 24px;
    box-sizing: border-box;

    *{
        user-select: text;
        ::selection{
            background: var(--color-rgb-invert);
        }
    }

    > *{
        max-width: 800px;
    }

    button{
        clip-path: polygon(0 0, calc(100% + 1px) 0, calc(100% - 0.75rem) 100%, 0 100%);
        background: linear-gradient(135deg, #55a51c, #488b18);
        color: white;
        font-weight: bold;
        &:hover{
            background: linear-gradient(135deg, #017632, #024f23);
            filter:brightness(1);
        }
    }
}

.cs-title-wrapper{
    padding-bottom: 2em;

    color: white;
}

.cs-textbox-wrapper{
    width: 100%;
    padding: 24px;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.2);
    box-sizing: border-box;

    p:last-child{
        margin-bottom: 0;
    }

    .cs-textbox-buttons{
        display:flex;
        flex-direction: row-reverse;
        justify-content: center;
        margin-top: 24px;
    }
}

.cs-benefit-list{
    --card-width: 200px;
    --card-height: 350px;

    width: 100%;
    padding: 24px;
    flex-grow: 1;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(var(--card-width), 1fr));
    gap: 24px;
}
</style>