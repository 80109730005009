<template>
    <div class="profile-view">
        <div class="badge">Patientenakte</div>
        <div class="profile-header">
            <img src="@/assets/content/img/profilepic.svg" alt="">
            <div class="info-wrapper">
                <div class="name">{{ patientenInfo.lastname }}, {{ patientenInfo.forename }} <span class="info-badge orange">Unvollständig</span></div>
                <div class="birth">geb. {{ patientenInfo.birth }}</div>
                <div class="address">{{ patientenInfo.address_town }}, {{ patientenInfo.address_street }}</div>
            </div>
        </div>

        <div class="profile-tab-list">
            <div class="profile-tab active">Dokumentation</div>
            <div class="profile-tab" v-for="tab in profileTabs">{{ tab }}</div>
        </div>

        <div class="profile-tab-navbar">
            <button class="dropdown no-bg inactive">Filter</button>
            <div class="date"> Datum: <span class="number inactive">01.01.2024 - 01.02.2024</span> </div>
            <button @click="emit('setStep',(3))">Fragebogen schicken</button>
            <button class="no-bg inactive">Neuer Beitrag +</button>
        </div>

        <div class="profile-tab-documentation">
            <div class="documentation-entry" v-for="entry in docEntries">
                <div class="timestamp">
                    <h3 class="date">{{ entry.date }}</h3>
                    <div class="time">{{ entry.time }}</div>
                </div>
                <div class="text">
                    <h3 class="title">{{ entry.title }}</h3>
                    <p>{{ entry.text }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from "vue"
const emit = defineEmits(["setStep"]);
const props = defineProps({
    state:{
        type: [Number],
        default:0, 
        required: true
    },
    nextStep:{
        type:Function,
        required: true
    },
})

function next(){
    if(props.state == 0)
        props.nextStep!;
}

var profileTabs = ref([
    "Dashboard","Vitalparameter","Verordnungsübersicht","Medikationsplan","Gravidität"
])

var docEntries = ref([
    {date:"01.01.2024",time:"12:56",title:"Erster Besuch",text:"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua."}
])

var patientenInfo = ref({
    forename:"Erika",
    lastname:"Mustermann",
    birth:"",
    address_town:"",
    address_street:""});

function updatePatientInfo(data:any){
    patientenInfo.value.forename = data.forename;
    patientenInfo.value.lastname = data.lastname;
    patientenInfo.value.birth = data.birth;
    patientenInfo.value.address_town = data.address_town;
    patientenInfo.value.address_street = data.address_street;
    checkCompleteness();
    console.log(patientenInfo.value);
}

function addDocEntry(entry:any){
    docEntries.value.push(entry);

    setTimeout(()=>{
        var entries = document.querySelectorAll(".profile-tab-documentation .documentation-entry");
        var lastEntry = entries[entries.length-1] as HTMLElement;
        lastEntry.scrollIntoView({behavior:"smooth"});
        lastEntry.style.backgroundColor = "rgba(85,165,28,0.5)";

        setTimeout(()=>{
            lastEntry.style.backgroundColor = "transparent";
        },1000);
    },0);
    
}

function checkCompleteness(){
    if(patientenInfo.value.birth != "" && patientenInfo.value.address_town != "" && patientenInfo.value.address_street != ""){
        document.querySelector(".profile-header .info-badge")?.classList.remove("orange");
        document.querySelector(".profile-header .info-badge")?.classList.add("green");
        document.querySelector(".profile-header .info-badge")!.innerHTML = "Vollständig";
    } else {
        document.querySelector(".profile-header .info-badge")?.classList.remove("green");
        document.querySelector(".profile-header .info-badge")?.classList.add("orange");
        document.querySelector(".profile-header .info-badge")!.innerHTML = "Unvollständig";
    }

}

onMounted(()=>{
    if(props.state == 1){
        setTimeout(() => {
            const newEntry = {
                date:"02.01.2024",
                time:"09:45",
                title:"Aufnahmebogen ausgefüllt",
                text: "---- wichtige Informationen ---- \r\n"
                    + "Regelmäßige Medikamenteneinnahme: ja\n​"
                    + "Bekannte Allergien: Penecillin​\n"
                    + "Bekannte Vorerkrankungen: Diabetes\n​"
            };
            addDocEntry(newEntry);

            var data = {
                forename:"Erika",
                lastname:"Mustermann",
                birth:"03.10.1993",
                address_town:"01234 Beispielstadt",
                address_street:"Beispielstraße 1"
            };
            updatePatientInfo(data);
        }, 4000);
    } else {
        var emptyProfile = {
            forename:"Erika",
            lastname:"Mustermann",
            birth:"",
            address_town:"",
            address_street:""
        };
        updatePatientInfo(emptyProfile);
    }
})
</script>

<style lang="scss">
.profile-view {
    --color-rgb: rgb(85,165,28);
    --paddingLeft: 8px;

    position: absolute;
    inset:0;
    display: flex;
    flex-direction: column;

    &>*{
        padding-left: var(--paddingLeft);
    }

    .badge{
        background-color: rgba(0,0,0,0.1);
        margin-bottom: 8px;

    }

    .profile-header{
        display: flex;
        flex-direction: row;
        gap: 8px;
        padding-left: var(--paddingLeft);

        img{
            height: 100px;
            aspect-ratio: 1/1;
        }

        .info-wrapper{
            display: flex;
            flex-direction: column;
            .name{
                font-weight: bold;
                font-size: 1.2em;
            }
            .address{
                flex-grow: 1;
                display:flex;
                flex-direction: column-reverse;
            }
        }
    }

    .profile-tab-list{
        display: flex;
        flex-direction: row;
        margin-top: 16px;

        .profile-tab{
            position: relative;
            padding: 8px 12px;
            cursor: not-allowed;
            
            &.active{
                cursor: default;
                font-weight: bold;
                color: var(--color-rgb);

                &::after{
                    content:'';
                    position:absolute;
                    height:4px;
                    bottom: 0px;
                    left:8px;
                    right:8px;
                    background: var(--color-rgb);
                }
            }

        }
    }

    .profile-tab-navbar{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
        background-color: rgba(0,0,0,0.3);

        .inactive{
            cursor: not-allowed;
        }

        button{
            height:40px;
            width: auto;
            padding:0 8px;
            background: var(--color-rgb);

            &.no-bg{
                background: transparent;
                color:inherit;
            }

            &.dropdown{
                &::after{
                    --arrow-size: 6px;
                    margin-left: 6px;
                    content:'';
                    border-top: calc(var(--arrow-size) / 2) transparent solid;
                    border-right: calc(var(--arrow-size) / 2) black solid;
                    border-bottom: calc(var(--arrow-size) / 2) black solid;
                    border-left: calc(var(--arrow-size) / 2) transparent solid;
                    transform: rotateZ(45deg) translateY(calc(var(--arrow-size) / -2));
                }
            }
        }

        :nth-child(2){
            flex-grow: 1;

            .number{
                padding: 0 4px;
                background:rgba(255,255,255,0.75);
            }
        }
    }

    .profile-tab-documentation{
        overflow-y:auto;
        flex-grow: 1;

        .documentation-entry{
            display: flex;
            flex-direction: row;
            gap: 32px;

            padding: 12px 0px;
            transition: background-color 0.3s ease-out;

            p{
                margin:0;
            }

            &:not(:last-child){
                border-bottom: 2px solid rgba(0,0,0,0.1);
            }

            .timestamp{
                .date{
                    margin-top:0;
                    padding-top:0;
                }
            }
            .text{
                flex-grow: 1;
                white-space: pre;
                .title{
                    margin-top:0;
                    padding-top:0;
                }
            }
        }
    }
}
</style>