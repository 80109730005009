import { Control } from "@babylonjs/gui/2D/controls/control";
import { StackPanel as StackPanelGUI } from "@babylonjs/gui/2D/controls/stackPanel";
import { TextBlock } from "@babylonjs/gui/2D/controls/textBlock";
import { Image as GUIImage } from "@babylonjs/gui/2D/controls/image";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";
import { Grid } from "@babylonjs/gui/2D/controls/grid";
import { ScrollViewer } from "@babylonjs/gui/2D/controls/scrollViewers/scrollViewer";
import { MeshBuilder } from "@babylonjs/core/Meshes/meshBuilder";
import { VideoTexture } from "@babylonjs/core/Materials/Textures/videoTexture";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { Color3 } from "@babylonjs/core/Maths/math.color";
import { Scene } from "@babylonjs/core/scene";
import { ActionManager } from "@babylonjs/core/Actions/actionManager";
import { ExecuteCodeAction } from "@babylonjs/core/Actions/directActions";
import { Texture } from "@babylonjs/core/Materials/Textures/texture";
import { WebXRHandJoint } from "@babylonjs/core/XR/features/WebXRHandTracking";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";

import { Flux360LogicSingleton } from "../../logic/Flux360LogicSingleton";
import { CustomDialogueHandler } from "../CustomVRDialogue";

export function constructVRPopUpContent(data:Content[], guiBase:Rectangle, vrDialogue:CustomDialogueHandler, scene:Scene){
    let scrollView = new ScrollViewer("Scroll Viewer");
    scrollView.barSize = 15;
    scrollView.scrollBackground = "#F1F1F1";
    scrollView.barBackground = "#C1C1C1";
    guiBase.addControl(scrollView)

    // Custom Scroll behavior with hand tracking
    let isTouching = false;
    let initialTouchPosition:Vector3 = new Vector3(0,0,0);
    let f = () => {
        let rightHand = vrDialogue.getRightHand();
        let fingerCollider = vrDialogue.getFingerCollider();
        let plane = vrDialogue.getPlane();
        if(rightHand && fingerCollider && plane){
            let indexFinger = rightHand.getJointMesh(WebXRHandJoint.INDEX_FINGER_TIP);

            if(fingerCollider.intersectsMesh(plane, true)){
                if(!isTouching){
                    initialTouchPosition = indexFinger.position.clone();
                    isTouching = true;
                }
            }else{
                if(isTouching){
                    isTouching = false;
                }
            }

            if(isTouching){
                let distance = indexFinger.position.y - initialTouchPosition.y;
                let scrollAmmount = distance * 8000 / contentBase.heightInPixels; // scrollammount in relation to the height of the plane
                initialTouchPosition = indexFinger.position.clone();
                scrollView.verticalBar.value += scrollAmmount;
            }
        }
    }   
    scrollView.onDisposeObservable.add(() => {
        scene.unregisterBeforeRender(f);
    });
    scene.registerBeforeRender(f);


    let contentBase = new StackPanelGUI("Stack Panel");   
    contentBase.adaptHeightToChildren = true;
    // contentBase.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP; // This will cause scrollview bugs when scrolling down. Create PG to report this bug!!
    contentBase.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    // contentBase.paddingBottom = contentBase.paddingLeft = contentBase.paddingRight = 40; // 40px padding top is from headline to workarround vertical alignment top bug from above!!!
    contentBase.onBeforeDrawObservable.add(() => {
        scrollView!.thumbLength = 500 / contentBase.heightInPixels; // Adjust size of scrollbar to match content length
    })
    scrollView.addControl(contentBase);

    data.forEach(content => {
        constructContent(content, contentBase, "white");
    });
}

function constructContent(content:Content, guiBase:StackPanelGUI, theme:string){
    if(content.type == "headline")
        createHeadline(content as Headline, guiBase, theme);

    if(content.type == "text")   
        createText(content as Text, guiBase, theme)

    if(content.type == "info_text")   
        createInfoText(content as InfoText, guiBase, theme)

    if(content.type == "image")   
        createImage(content as Image, guiBase)

    if(content.type == "stackpanel")   
        createStackPanel(content as StackPanel, guiBase)

    if(content.type == "accordion")   
        createAccordion(content as Accordion, guiBase)

    if(content.type == "video" && (content as Video).displayControls)
        constructFullscreenVideo(content as Video, guiBase);
}

function createHeadline(content:Headline, guiBase:StackPanelGUI, theme:string){
    let text = new TextBlock("Headline", content.text.toUpperCase());
    text.fontFamily = "OpenSansRegular";
    text.fontWeight = "bold";
    text.fontSize = "36px";
    text.color = theme == "white" ? "#99c877" : "white";
    text.resizeToFit = true;
    text.textWrapping = true;
    text.paddingBottom = 15;
    text.paddingTop = 50;
    text.paddingLeft = text.paddingRight = 50;
    text.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    text.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    guiBase.addControl(text);
}

function createText(content:Text, guiBase:StackPanelGUI, theme:string){
    let paddingRectangle = new Rectangle("Padding");
    paddingRectangle.adaptHeightToChildren = true;
    paddingRectangle.paddingBottom = 10;
    paddingRectangle.paddingLeft = paddingRectangle.paddingRight = 50;
    paddingRectangle.thickness = 0;
    guiBase.addControl(paddingRectangle);

    let stackpanel = new StackPanelGUI("Stack Panel");   
    stackpanel.isVertical = true;
    stackpanel.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
    stackpanel.adaptHeightToChildren = true;
    paddingRectangle.addControl(stackpanel);
    
    let filteredText = content.text.replaceAll("<strong>","").replaceAll("</strong>","");
    let textElements = filteredText.split("<p>").filter(t => t.length > 0);
    for (let i = 0; i < textElements.length; i++) {
        let text = textElements[i].split("</p>")[0];
        let textBlock = new TextBlock("Text", text);
        textBlock.color = theme == "white" ? "black" : "white";
        textBlock.fontFamily = "OpenSansRegular";
        textBlock.fontSize = "22px";
        textBlock.textWrapping = true;
        textBlock.paddingBottom = 15;
        textBlock.resizeToFit = true;
        textBlock.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
        stackpanel.addControl(textBlock);
    }
}

function createInfoText(content:InfoText, guiBase:StackPanelGUI, theme:string){
    let master = new Rectangle("InfoText");
    master.thickness = 0;
    master.adaptHeightToChildren = true;
    master.paddingBottom = 20;
    guiBase.addControl(master);

    // Background (For Border and Color)
    let rectangle = new Rectangle("InfoText-Background");
    rectangle.adaptHeightToChildren = true;
    rectangle.thickness = 0;
    rectangle.paddingLeft = rectangle.paddingRight = 50;
    if(content.style == 0){
        rectangle.thickness = 2;
        rectangle.color = theme == "white" ? "#99c877" : "white";
    }
    if(content.style == 1){
        rectangle.background = "#E4F0D6";
    }
    master.addControl(rectangle);

    // Rectangle for padding (left, right, top, bot)
    let paddingRectangle = new Rectangle("Padding");
    paddingRectangle.adaptHeightToChildren = true;
    paddingRectangle.paddingLeft = paddingRectangle.paddingRight = 20;
    paddingRectangle.paddingTop = paddingRectangle.paddingBottom = 30;
    paddingRectangle.thickness = 0;
    if(content.style == 2){
        paddingRectangle.paddingLeft = paddingRectangle.paddingRight = paddingRectangle.paddingTop = paddingRectangle.paddingBottom = 0;
    }
    rectangle.addControl(paddingRectangle);

    let base = new StackPanelGUI("InfoText-Stackpanel");   
    base.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    base.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    paddingRectangle.addControl(base);

    if(content.title){
        let text = content.title.replaceAll("<strong>","").replaceAll("</strong>","");
        let title = new TextBlock("Title", text);
        title.fontFamily = "OpenSansRegular";
        title.fontSize = "22px";
        title.textWrapping = true;
        title.paddingBottom = 10;
        title.resizeToFit = true;
        title.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
        title.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
        if(content.style == 0){
            title.color = theme == "white" ? "#99c877" : "white";
        }
        base.addControl(title);
    }

    // Create Bulletpoints
    if(content.bulletpoints){
        content.bulletpoints.forEach((bulletpoint,i) => {
            let text = bulletpoint.text.replaceAll("<strong>","").replaceAll("</strong>","");
            text = text.replace("<a href='https://diga.bfarm.de'>https://diga.bfarm.de</a>","https://diga.bfarm.de")
            let textBlock = new TextBlock("Text", text);
            textBlock.fontFamily = "OpenSansRegular";
            textBlock.fontSize = "22px";
            textBlock.color = theme == "white" ? "black" : "white";
            textBlock.textWrapping = true;
            textBlock.paddingBottom = 10;
            textBlock.resizeToFit = true;
            textBlock.paddingLeft = 10;
            textBlock.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
            textBlock.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
            if(content.style == 0){
                textBlock.text = i+1 + "." + textBlock.text;
            }
            if(content.style == 1){
                textBlock.paddingLeft = 10;
                base.addControl(textBlock);
            }

            if(content.style == 0 || content.style == 2){     
                let container = new StackPanelGUI("Text-BulletPoint-Container");   
                container.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
                container.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
                container.isVertical = false;
                container.adaptHeightToChildren = true;
                container.paddingLeft = "10px";
                base.addControl(container);
        
                let point = new Rectangle("BulletPoint");
                point.cornerRadius = 9999;
                point.top = 5;
                point.thickness = 0;
                point.width = "10px";
                point.height = "10px";
                point.background = theme == "white" ? "#99c877" : "white";
                point.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
                point.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
                container.addControl(point);

                container.addControl(textBlock);
            }
        });
    }
}

function createImage(content:Image, guiBase:StackPanelGUI){
    let image = new GUIImage("Image", '/src/assets/content/img/' + content.url);

    image.paddingBottom = image.paddingTop = 20;
    image.height = "20px"; // Set Pseudo width so GUI wont crash because of percentage height in stackpanel

    image.onImageLoadedObservable.add(() => {
        if(content.url.includes(".svg")){
            fetch('/src/assets/content/img/' + content.url)
            .then(response => response.text())
            .then(svgText => {
                const parser = new DOMParser();
                const svgDoc = parser.parseFromString(svgText, 'image/svg+xml');
                const svgElement = svgDoc.documentElement;
                const bbox = svgElement.getAttribute('viewBox')!.split(' ').map(parseFloat);
    
                image.height = bbox[3]/2 + "px";
                image.width = bbox[2]/2 + "px";
                image.sourceHeight = bbox[3];
                image.sourceWidth = bbox[2];
            });
        } else {
            image.width = "500px";
            image.height = "500px";
            if(content.width)
                image.width = "50px";
            if(content.height)
                image.height = "50px";
            image.sourceWidth = 1400;
            image.sourceHeight = 1400;
            image.stretch = GUIImage.STRETCH_UNIFORM;
            image.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;

            const img = new Image();
            img.onload = function() {
                image.sourceWidth = img.width;
                image.sourceHeight = img.height;
            }
            img.src = '/src/assets/content/img/' + content.url
        }
    });
    guiBase.addControl(image);
}

function createStackPanel(container:StackPanel, guiBase:StackPanelGUI){
    let stackpanel = new StackPanelGUI("Stack Panel");
    stackpanel.isVertical = container.isVertical;
    stackpanel.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    stackpanel.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    stackpanel.adaptHeightToChildren = true;
    stackpanel.paddingBottom = 40;
    stackpanel.background = container.backgroundColor ? container.backgroundColor : "white";
    guiBase.addControl(stackpanel);

    container.content.forEach(content => {
        constructContent(content, stackpanel, container.backgroundColor ? container.backgroundColor : "white");
    });

    // Image & Text
    if(container.content.length == 2 && !container.isVertical){
        stackpanel.paddingLeft = stackpanel.paddingRight = 50;
        stackpanel.children.forEach((child, i) => {
            if(i==0)
                child.paddingRight = "10px";
            if(child.getClassName() == "StackPanel"){

            }
            if(child.getClassName() == "Image"){
                child.paddingRight = "10px";
            }
        });
        stackpanel.paddingLeft = stackpanel.paddingRight = 40;
    }
}

function createAccordion(content:Accordion, guiBase:StackPanelGUI){
    let contentContainer = new StackPanelGUI("Content Container");   
    contentContainer.isVertical = true;
    contentContainer.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    contentContainer.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;

    let base = new StackPanelGUI("Accordion Base");   
    base.isVertical = true;
    base.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    base.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
    base.width = "90%";
    guiBase.addControl(base);

    let titleContainer = new Grid("Title Container");
    // titleContainer.adaptHeightToChildren = true; // Not working here idk why
    titleContainer.height = "100px";
    titleContainer.paddingLeft = "10px";
    titleContainer.hoverCursor = "pointer";
    titleContainer.onPointerClickObservable.add(() => {
        if(base.containsControl(contentContainer)){
            base.removeControl(contentContainer);
            arrow.rotation = 0;
        }else{
            base.addControl(contentContainer);
            arrow.rotation = Math.PI/2;
        }
    })
    titleContainer.addColumnDefinition(0.1);
    titleContainer.addColumnDefinition(0.8);
    titleContainer.addColumnDefinition(0.1);
    base.addControl(titleContainer);

    let icon = new GUIImage("Image", '/src/assets/content/img/' + content.icon);
    icon.width = "80px";
    icon.height = "80px";
    icon.sourceWidth = 1024;
    icon.sourceHeight = 1024;
    titleContainer.addControl(icon,0,0);

    let text = new TextBlock("Title", content.title);
    text.fontFamily = "OpenSansRegular";
    text.fontSize = "24px";
    text.color = "black";
    text.resizeToFit = true;
    text.paddingLeft = "10px";
    text.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    titleContainer.addControl(text,0,1);

    let arrow = new GUIImage("Image", "src/assets/content/img/arrow_drop_down_circle_closed.svg");
    arrow.width = "60px";
    arrow.height = "60px";
    arrow.sourceWidth = 1024;
    arrow.sourceHeight = 1024;
    titleContainer.addControl(arrow,0,2);

    let line = new Rectangle("Line");
    line.height = "2px"
    line.thickness = 0;
    line.background = "#99c877";
    base.addControl(line); 
    
    let space = new Rectangle("Space");
    space.height = "20px"
    space.thickness = 0;
    base.addControl(space); 

    content.content.forEach(content => {
        constructContent(content, contentContainer, "white");
    });
}

function constructFullscreenVideo(video:Video, guiBase:StackPanelGUI){
    let scene = Flux360LogicSingleton.getInstance().getGraphicsEngine().getScene()! as Scene;

    // VideoTexture
    let videoTexture = new VideoTexture("Tablet Video Texture", "/src/assets/content/video/" + video.url, scene, true, true, VideoTexture.BILINEAR_SAMPLINGMODE, { 
        autoPlay: true
    });

    // Material
    let videoMaterial = new StandardMaterial("Tablet Video Material", scene);
    videoMaterial.diffuseTexture = videoTexture; 
    videoMaterial.emissiveColor = Color3.White();
    videoMaterial.disableLighting = true;   
    videoMaterial.backFaceCulling = false;

    // Plane
    let plane = MeshBuilder.CreatePlane("Video Plane", {width: 0.255, height: 0.19}, scene);
    plane.parent = scene.getMeshById("CustomDialogue");
    plane.scaling.y = -1;
    plane.position.z = -0.0001;
    plane.renderingGroupId = 1;
    plane.material = videoMaterial;

    // Create Close Button
    let closeButtonMaterial = new StandardMaterial("Close Material", scene);
    closeButtonMaterial.emissiveTexture = closeButtonMaterial.opacityTexture = new Texture("/src/assets/vr/img/close.png", scene); 
    closeButtonMaterial.disableLighting = true;   
    closeButtonMaterial.backFaceCulling = false;

    let closeButton = MeshBuilder.CreatePlane("Close Plane", {width: 0.03, height: 0.03}, scene);
    closeButton.parent = scene.getMeshById("CustomDialogue");
    closeButton.scaling.y = -1;
    closeButton.position.z = -0.00015;
    closeButton.position.y = 0.08;
    closeButton.position.x = 0.111;
    closeButton.renderingGroupId = 1;
    closeButton.material = closeButtonMaterial;
    closeButton.isNearPickable = true;

    closeButton.actionManager = new ActionManager(scene);
    closeButton.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickTrigger,() => {
        scene.getMeshById("CustomDialogue")!.getChildMeshes().forEach(child => { child.dispose(); });
        if(scene.getTextureByName("Tablet Video Texture")){
            (scene.getTextureByName("Tablet Video Texture") as VideoTexture).video.pause();
            scene.getTextureByName("Tablet Video Texture")!.dispose();
        }
    }));
}