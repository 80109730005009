<template>
    <div class="questionaires">
        <div class="questionaires-panel">
            <div class="questionaires-panel-header">
                <div class="input-text-wrapper hasIcon">
                    <img src="@/assets/content/img/search_icon.svg" alt="">
                    <input type="text" placeholder="Fragebogensuche" disabled>
                </div>
                <div class="close-btn"/>
            </div>
            <div class="questionaires-panel-body">
                <div v-if="state == 0" v-for="item in collection" class="questionaires-list">
                    <h4>{{item.label}}</h4>
                    <ul>
                        <li v-for="questionaire in item.list" :class="{'allowed': questionaire.includes('Dipraxis'), 'marked': (marked && questionaire.includes('Dipraxis'))}" @click="marked  = !marked">
                            {{questionaire}}
                            <img src="@/assets/content/img/edit_icon.svg" alt="">
                            <img src="@/assets/content/img/open-in_icon.svg" alt="">
                        </li>
                    </ul>
                </div>
                <div v-if="state == 1" class="questionaire-profile">
                    <div class="qr-code-wrapper">
                        <img src="@/assets/content/img/qr_code-kvwl.jpg" alt="">
                        <div class="qr-code-buttons">
                            <button class="gray" disabled>Download</button>
                            <button class="gray" disabled>Senden</button>
                        </div>
                    </div>
                    <div class="contact-form-wrapper">
                        <h3>Dipraxis: Aufnahmebogen</h3>
                        <input type="text" v-model="email" placeholder="E-Mail-Adresse des Patienten" @click="fillEmail()">
                        <textarea placeholder="Nachricht an den Patienten" v-model="textarea" @click="fillTextarea()"/>
                        <div class="attachment-container">
                            <div class="attachment document">Fragebogen</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="questionaires-panel-footer">
                <button v-if="state == 0" class="green" :disabled="!marked" @click="nextStep!">Auswählen</button>
                <button v-if="state == 1" class="green" :disabled="!filled" @click="nextStep!">Verschicken</button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {computed, onMounted, ref} from "vue"
const props = defineProps({
    state: Number,
    nextStep: Function
})
const collection = ref([
    {
        label:"Eigene Fragebögen",
        list:[
            "Dipraxis: Aufnahmebogen"
        ]
    },
    {
        label:"Corona-Virus",
        list:[
            "Long Covid Syndrom",
            "Post-Covid-19 Skala des funktionellen Status",
            "Covid-19 Risikofragebogen",
            "Covid-19 & Infekt Screening",
            "Covid-19 Verlaufsfragebogen",
        ]
    },
    {
        label:"Formulare: Patienteninfromation & Einwilligungserklärungen",
        list:[
            "Patienteninformationen zum Datenschutz (DSGVO)",
            "Patienteninformationen zur Laborreform",
            "Patientenverfügung",
            "Benachrichtigungen (SMS, E-Mail)",
            "Externe Befunde",
            "Versicherungsdaten (Neue Patienten/Aktualisierung)",
            "Einverständnis Anforderung & Übermittlung von Patientendaten (Hausarztversion)",
            "Einverständnis Anforderung & Übermittlung von Patientendaten (Facharztversion)",
        ]
    },
])

var marked = ref(false);
var email = ref('');
var textarea = ref('');
var filled = computed(()=>{
    return email.value.length > 0 ? true : false;
})

function fillEmail(){
    email.value = 'Erika.Mustermann@email.com';
}
function fillTextarea(){
    textarea.value = 'Sehr geehrte Frau Mustermann,\n\nbitte füllen Sie den Fragebogen aus und senden Sie ihn zurück.\nVielen Dank!\n\nMit freundlichen Grüßen,\nIhr Praxisteam';
}

</script>

<style lang="scss" scoped>
.questionaires{
    position:absolute;
    inset:0;
    background-color: rgba(0,0,0,0.7);
    display:flex;
    align-items: center;
    justify-content: center;
}

.questionaires-panel{
    $color-rgb: rgb(85, 165, 28);
    width: 700px;
    background: white;

    .questionaires-panel-header{
        background: rgba(0,0,0,0.2);
        display:flex;
        align-items: center;
        justify-content: space-between;

        .close-btn{
            position:relative;
            height: 48px;
            aspect-ratio: 1/1;

            &::after,&::before{
                content:'';
                position:absolute;
                top:50%;
                left:50%;
                width: 2px;
                height: 20px;
                background: rgba(0,0,0,1);
                transform: translate(-50%,-50%) rotate(45deg);
            }
            &::before{
                transform: translate(-50%,-50%) rotate(-45deg);
            }
        }
    }

    .questionaires-panel-body{
        height: 400px;
        overflow-y: auto;
        box-sizing: border-box;
        
        .questionaires-list{
            h4{
                padding:0;
                margin:0;
                margin-left:24px;
            }
            ul{
                margin:0;
                padding:2px 6px;
                list-style-type: none;
                li{
                    position: relative;
                    padding:4px;
                    padding-left:48px;
                    cursor:not-allowed;
                    pointer-events: none;
                    user-select: none;
    
                    &.allowed{
                        cursor: pointer;
                        pointer-events: all;
                    }
    
                    &.marked{
                        font-weight: bold;
    
                        &::after{
                            content:'';
                            position: absolute;
                            left: 24px;
                            top:50%;
                            height:1em;
                            aspect-ratio: 1/1;
                            transform: translateY(-50%);
                            border-radius: 50%;
                            background: url('../../../../assets/content/img/done-icon-white.svg') rgba($color-rgb,1);
                            background-position: center center;
                            background-size: contain;
                        }
                    }
    
                    img{
                        height:1em;
                        opacity:0;
                    }
    
                    &:hover{
                        background: rgba($color-rgb,0.1);
    
                        img{
                            opacity:0.5;
                        }
                    }
                }
            }
        }

        .questionaire-profile{
            height:100%;
            box-sizing: border-box;
            display:flex;
            flex-direction: row;
            gap:8px;
            align-items: flex-start;
            padding:8px;
            background: rgba(0,0,0,0.1);

            .qr-code-wrapper,.contact-form-wrapper{
                background: white;
                padding: 8px;
            }

            .qr-code-wrapper{
                img{
                    width: 200px;
                }
                .qr-code-buttons{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }
            }
            .contact-form-wrapper{
                flex-grow: 1;
                align-self: stretch;
                overflow-y: auto;

                display: flex;
                flex-direction: column;
                gap:4px;

                input[type=text],textarea{
                    background: rgba(0,0,0,0.1);
                    color: black;
                    border:none;
                    font-weight: bold;
                    padding: 12px;
                    box-sizing: border-box;
                    &::placeholder{
                        color:rgba(0,0,0,0.5)
                    }
                }
                input[type=text]{
                    height: unset !important;
                }
                textarea{
                    flex-grow: 1;
                    text-align: start;
                    resize: none;
                }

                .attachment-container{
                    display:flex;
                    flex-direction: row;

                    .attachment{
                        display:flex;
                        flex-direction: row;
                        align-items: center;
                        padding:4px;
                        background: rgba(0,0,0,0.4);
                        font-size: 0.8em;
                        &:before,&:after{
                            content:'';
                            display:block;
                            height: 1.2em;
                            aspect-ratio: 1/1;
                            background-size: contain;
                        }
                        &:before{
                            background-image: url("../../../../assets/content/img/file-icon.svg");
                        }
                        &:after{
                            background-image: url("../../../../assets/content/img/close-icon.svg");
                        }
                    }
                }    
            }
        }
    }

    .questionaires-panel-footer{
        display:flex;
        flex-direction: row-reverse;
        padding:6px;
    }
}

button{
    $color-rgb: rgb(85, 165, 28);
    height:40px !important;
    width: auto;
    padding:0 8px !important;
    font-weight: bold;

    &:disabled{
        opacity: 0.5 !important;
    }

    &.gray{
        background: rgba(0,0,0,0.2)
    }

    &.green{
        background: $color-rgb;
    }
}

.input-text-wrapper{
    $icon-size: 24px;
    $input-height: 32px;
    $spacing: calc(($input-height - $icon-size) / 2);
    $input-opacity: 0.5;

    position: relative;
    margin-left: 16px;

    &.hasIcon{
        input[type=text]{
            padding-left: calc( $icon-size + ($spacing * 2));
        }

        img{
            position: absolute;
            left: $spacing;
            top:50%;
            transform: translateY(-50%);
            height:$icon-size;
            width: $icon-size;

            opacity: $input-opacity;
        }
    }

    input[type=text]{
        position: relative;
        background: transparent;
        font-size: 1em;
        border-bottom: 1px solid rgba(0,0,0,0.3);
        padding: none;
        height: $input-height;

        &:focus{
            outline:none;
        }

        &::placeholder{
            font-weight: 500;
            color: rgba(0,0,0,$input-opacity);
        }
    }
}
</style>