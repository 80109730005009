import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { MeshBuilder } from "@babylonjs/core/Meshes/meshBuilder";
import { Scene } from "@babylonjs/core/scene";
import { AdvancedDynamicTexture } from "@babylonjs/gui/2D/advancedDynamicTexture";
import { SceneLoader } from "@babylonjs/core/Loading/sceneLoader";
import { TransformNode } from "@babylonjs/core/Meshes/transformNode";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { BoundingInfo } from "@babylonjs/core/Culling/boundingInfo";
import { Flux360LogicSingleton } from "../logic/Flux360LogicSingleton";
import { ExecuteCodeAction } from "@babylonjs/core/Actions/directActions";
import { ActionManager } from "@babylonjs/core/Actions/actionManager";
import { CubicEase, EasingFunction } from "@babylonjs/core/Animations/easing";
import { Animation } from "@babylonjs/core/Animations/animation";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { Color3 } from "@babylonjs/core/Maths/math.color";
import { WebXRInput } from "@babylonjs/core/XR/webXRInput";
import { WebXRExperienceHelper } from "@babylonjs/core/XR/webXRExperienceHelper";
import { WebXRFeatureName } from "@babylonjs/core/XR/webXRFeaturesManager";
import { WebXRHand, WebXRHandJoint, WebXRHandTracking } from "@babylonjs/core/XR/features/WebXRHandTracking";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";
import { VideoTexture } from "@babylonjs/core/Materials/Textures/videoTexture";
import { Control } from "@babylonjs/gui/2D/controls/control";
import "@babylonjs/core/Materials/standardMaterial"; // Needed for AdvancedDynamicTexture
import "@babylonjs/loaders/glTF"; // Needed for Tablet loading
import "@babylonjs/core/Meshes/subMesh.project"; // Needed for near interaction
// import { SixDofDragBehavior } from "@babylonjs/core/Behaviors/Meshes/sixDofDragBehavior";

import type { SituationComponent } from "flux-360-logic/dist/src/core/situationComponents/SituationComponent";
import content_json from '@/assets/content/content.json';
import { constructSelfAssesmentContent } from "./components/VRSelfAssassment";
import { constructVRPopUpContent } from "./components/VRPopupTypes";
import { constructDigitaleAnamneseContent } from "./components/VRDigitaleAnamnese";

export class CustomDialogueHandler {

    // Progress Bar when selecting buttons
    private loadingscreen:Mesh|undefined;
    private loadingProgress:Rectangle|undefined;

    // Tablet
    private guiBase:Rectangle|undefined;
    private collider:Mesh|undefined;
    private ray:Mesh|undefined;
    private plane:Mesh|undefined;

    // Main Node & Container
    private root:TransformNode|undefined;
    private container:TransformNode|undefined;

    // Grabbing
    private isGrabbed = false;
    private rightHand:WebXRHand|undefined;
    private fingerCollider:Mesh|undefined;

    public setup(scene:Scene){
        this.root = new TransformNode("Tablet + Dialogue", scene);
        this.root.setEnabled(false); // Enable when user enters VR!

        this.container = new TransformNode("Container", scene);
        this.container.parent = this.root;
        this.container.position.x = 0.12;
        this.container.position.y = 0.15;
        this.container.position.z = 0.07;
        this.container.rotation.z = Math.PI + Math.PI/3;

        this.loadTablet(scene);
        this.setupPlane(scene);
        this.createTabletRay(scene);
        this.createLoadingCircle(scene);
        this.handleHandtracking();
        this.handleControllers();

        // Enable Dialogue when user enters VR
        Flux360LogicSingleton.getInstance().getGraphicsEngine().getLifeCycleHooks().registerOnAfterVREnter(() => {
            this.root!.setEnabled(true);
        });
    }

    private setupPlane(scene:Scene){
        let plane = MeshBuilder.CreatePlane("CustomDialogue", {width: 0.265, height: 0.265}, scene);
        plane.parent = this.container!;
        plane.isNearPickable = true;
        plane.scaling.z = -1;
        plane.scaling.x = -1;
        plane.renderingGroupId = 1;

        let advancedTexture = AdvancedDynamicTexture.CreateForMesh(plane, 1024, 1024);
        plane.material!.backFaceCulling = false;

        this.guiBase = new Rectangle("Base");
        this.guiBase.background = "white";
        this.guiBase.height = "75%";
        this.guiBase.cornerRadius = 20;
        this.guiBase.thickness = 0;
        advancedTexture.addControl(this.guiBase);

        this.plane = plane;
    }

    private loadTablet(scene:Scene){
        SceneLoader.ImportMesh("", "/src/assets/vr/", "KVWL_20240322_IPad_12,9_V04-comp.glb", scene, (meshes) => {
            let tablet = meshes[0] as Mesh;
            tablet.rotation = new Vector3(0, Math.PI, Math.PI/2);
            tablet.position.z = -0.005;
            tablet.name = "Tablet";
            tablet.parent = this.container!;
            tablet.isPickable = false;
            
            // Set correct BoungindBox to main tablet node for Grabbing
            let meshMinMax = Mesh.MinMax(meshes);
            let boundingInfo = new BoundingInfo(meshMinMax.min,meshMinMax.max);
            let dimensions = (boundingInfo.boundingBox.extendSizeWorld).scale(2.5);
            this.collider = MeshBuilder.CreateBox("Collider", {width: dimensions.x, height: dimensions.y, depth: dimensions.z}, scene);
            this.collider.rotation.z = Math.PI/2;
            this.collider.isVisible = false;
            this.collider.parent = this.container!;

            // let dragBehavior = new SixDofDragBehavior();
            // dragBehavior.onDragStartObservable.add(() => {
            //     this.isGrabbed = true;
            // });
            // dragBehavior.onDragEndObservable.add(() => {
            //     this.isGrabbed = false;
            // });
            // this.collider.addBehavior(dragBehavior);
            // this.root!.getChildMeshes(false).forEach(child => {
            //     child.isNearGrabbable = true;
            // });

            // Fix Rendering order
            meshes.forEach(mesh => {
                mesh.renderingGroupId = 1; 
                // if(mesh.material){
                //     (mesh.material as StandardMaterial).disableLighting = true;
                //     (mesh.material as StandardMaterial).emissiveColor = Color3.White();
                // }
            });
        });
    }

    private createTabletRay(scene:Scene){
        this.ray = MeshBuilder.CreateCylinder("Ray", {height: 200, diameterBottom: 10, diameterTop: 0.001}, scene);
        this.ray.rotation.x = Math.PI/2;
        this.ray.position = new Vector3(0.115, 0.08, -100.01);
        this.ray.parent = this.container!;
        this.ray.visibility = 0.6;
        this.ray.renderingGroupId = 1;
        this.ray.setEnabled(false);
        let rayMaterial = new StandardMaterial("Ray Material", scene);
        rayMaterial.emissiveColor = Color3.FromHexString("#5CA81A");
        rayMaterial.disableLighting = true;
        this.ray.material = rayMaterial;

        var ease = new CubicEase();
        ease.setEasingMode(EasingFunction.EASINGMODE_EASEIN);
        
        // Ray Interaction with Button/Transition
        Flux360LogicSingleton.getInstance().getLifeCycleHooks().registerOnAfterSituationComponentCreated((situationComponent:SituationComponent) => {   
            if(situationComponent.getConfig().type == "Button" || situationComponent.getConfig().type == "Transition"){

                let timerID:NodeJS.Timeout;

                // @ts-ignore
                let plane = situationComponent.plane;
                plane.actionManager.registerAction(new ExecuteCodeAction({ trigger: ActionManager.OnIntersectionEnterTrigger, parameter: { mesh: this.ray, usePreciseIntersection: true} }, () => {
                    plane.scaling = new Vector3(1.2, 1.2, 1.2); // Animation.CreateAndStartAnimation("", plane, "scaling", 60, 20, plane.scaling, new Vector3(1.5, 1.5, 1.5), 0, ease);  // Animation not working??????

                    this.loadingscreen!.setEnabled(true); // Display loading circle on Tablet
                    scene.beginAnimation(this.loadingProgress, 0, 60);

                    clearTimeout(timerID);
                    timerID = setTimeout(() => {
                        this.loadingscreen!.setEnabled(false); // "Loading done" Hide Loadingscreen

                        // Button "Click"
                        if(situationComponent.getConfig().type == "Button"){
                            this.constructContent(situationComponent.getConfig().url, scene);
                        }

                        // Transiton "Click"
                        if(situationComponent.getConfig().type == "Transition"){
                            Flux360LogicSingleton.getInstance().getTour().changeSituation(situationComponent.getConfig().targetSituationID, {playAnimation: false});
                        }
                    }, 1000);
                }));

                // "Hover-Out" Animation
                plane.actionManager.registerAction(new ExecuteCodeAction({ trigger: ActionManager.OnIntersectionExitTrigger, parameter: { mesh: this.ray, usePreciseIntersection: true} }, () => {
                    clearTimeout(timerID);
                    this.loadingscreen!.setEnabled(false); // Remove loading circle on Tablet
                    plane.scaling = new Vector3(1, 1, 1); // Animation.CreateAndStartAnimation("", plane, "scaling", 60, 20, plane.scaling, Vector3.One(), 0, ease); 
                }));
            }
        });   
    }

    private constructContent(url:string, scene:Scene){
        this.guiBase!.children.forEach(child => { setTimeout(() => { child.dispose(); }, 0); }); // Cleanup dialogue!
        scene.getMeshById("CustomDialogue")!.getChildMeshes().forEach(child => { child.dispose(); });
        if(scene.getTextureByName("Tablet Video Texture")){
            (scene.getTextureByName("Tablet Video Texture") as VideoTexture).video.pause();
            scene.getTextureByName("Tablet Video Texture")!.dispose();
        }

        if(url == "Self Assassment"){
            constructSelfAssesmentContent(this.guiBase!)
        }

        if(url == "Digitale Anamnese"){
            constructDigitaleAnamneseContent(this.guiBase!, this)
        }

        let data = content_json.filter(x =>x.id == url)[0] as PopupData;
        if(data){
            constructVRPopUpContent(data.content, this.guiBase!, this, scene);
        }
    }

    private handleHandtracking(){
        let xrHelper:WebXRExperienceHelper|undefined = undefined;

        // Get XR Helper
        Flux360LogicSingleton.getInstance().getGraphicsEngine().getLifeCycleHooks().registerOnAfterVRSetup((xrHelperHere:WebXRExperienceHelper) => {
            xrHelper = xrHelperHere;
        });

        // GRABBING Tablet
        Flux360LogicSingleton.getInstance().getGraphicsEngine().getLifeCycleHooks().registerOnAfterVRSetup(() => {
            let scene = Flux360LogicSingleton.getInstance().getGraphicsEngine().getScene()! as Scene;
            const xrHandFeature = xrHelper!.featuresManager.getEnabledFeature(WebXRFeatureName.HAND_TRACKING) as WebXRHandTracking;
            xrHandFeature.onHandAddedObservable.add(() => {

                // Fix Render order of Hands
                let leftHand = xrHandFeature.getHandByHandedness("left");
                let rightHand = xrHandFeature.getHandByHandedness("right");
                if(rightHand){
                    this.rightHand = rightHand;
                    this.fingerCollider = MeshBuilder.CreateBox("Finger Collider", {width: 3, height: 3, depth: 5}, scene);
                    this.fingerCollider.isVisible = false;
                    this.fingerCollider.parent = rightHand.getJointMesh(WebXRHandJoint.INDEX_FINGER_TIP);
                }
                setTimeout(() => {
                    [leftHand, rightHand].forEach(hand => {
                        if(hand && hand!.handMesh){
                            hand.handMesh.renderingGroupId = 1; // Fix Render Order
                            let handMaterial = new StandardMaterial("",scene);
                            handMaterial.diffuseColor = Color3.White();
                            handMaterial.specularColor = Color3.Black();
                            hand.handMesh.material = handMaterial;
                        }
                    });
                }, 1000);

                // Grabbing
                let hand = xrHandFeature.getHandByHandedness("left");
                if(hand){
                    let indexFinger = hand.getJointMesh(WebXRHandJoint.INDEX_FINGER_TIP);
                    let thumb = hand.getJointMesh(WebXRHandJoint.THUMB_TIP);

                    scene.onBeforeRenderObservable.add(() => {
                        let distance = Vector3.Distance(indexFinger.position, thumb.position);

                        if(this.isGrabbed == false && distance < 0.03 && (indexFinger.intersectsMesh(this.collider!) || thumb.intersectsMesh(this.collider!)) ){
                            this.isGrabbed = true;
                            this.ray!.setEnabled(true);
                            this.root!.setParent(indexFinger);
                            // this.root!.position = indexFinger.position.clone();
                        }
                        
                        if(this.isGrabbed == true && distance > 0.03){
                            this.isGrabbed = false;
                            this.ray!.setEnabled(false);
                            this.root!.setParent(null);
                        }

                        // if(this.isGrabbed){
                        //     let d = Vector3.Distance(indexFinger.position, this.root!.position);
                        //     if(d > 0.005){
                        //         this.root!.position = indexFinger.position.clone();
                        //         this.root!.rotation = indexFinger.rotationQuaternion!.toEulerAngles();
                        //     }
                        // }
                    });
                }
            });
        });

        // Handle Tablet Position
        Flux360LogicSingleton.getInstance().getGraphicsEngine().getLifeCycleHooks().registerOnVRInputAdded((input:WebXRInput) => {
            input.onControllerAddedObservable.add((controller) => {
                controller.onMotionControllerInitObservable.add((motionController) => {
                    if(motionController.handedness == "right"){
                        input.xrSessionManager.onXRFrameObservable.add(() => { // On Every Frame positon custom dialogue at the controller/hand position
                            if(this.root && controller.grip && !this.isGrabbed){
                                this.root.position = controller.grip.position.clone();
                                this.root.rotation = controller.grip.rotationQuaternion!.toEulerAngles();
                            }
                        });
                    }
                });
            });
        });
    }

    private handleControllers(){
        // Flux360LogicSingleton.getInstance().getGraphicsEngine().getLifeCycleHooks().registerOnVRInputAdded((input:WebXRInput) => {
        //     input.onControllerAddedObservable.add((controller) => {
        //         controller.onMotionControllerInitObservable.add((motionController) => {
        //             if(controller.inputSource.hand) // hands are controllers too do not want to go do this code; when it is a hand
        //                 return;

        //             // Add Vibration on hover over Button and Transition
        //             Flux360LogicSingleton.getInstance().getLifeCycleHooks().registerOnSituationComponentHoverOver((situationComponent:SituationComponent) => {   
        //                 if(situationComponent.getConfig().type == "Button" || situationComponent.getConfig().type == "Transition"){
        //                     motionController.pulse(0.5, 100);
        //                 }
        //             });

        //             // Controll Dialogue scroll via Thumbstick
        //             const xr_ids = motionController.getComponentIds();
        //             let thumbstickComponent = motionController.getComponent(xr_ids[2]); // xr-standard-thumbstick
        //             thumbstickComponent.onAxisValueChangedObservable.add((axes) => {
        //                 if(scrollView){
        //                     scrollView.verticalBar.value += axes.y * 0.05;
        //                 }
        //             });
        //         });
        //     });
        // });
    }

    public createLoadingCircle(scene:Scene){
        this.loadingscreen = MeshBuilder.CreatePlane("Loadingscreen", {width: 0.265, height: 0.265}, scene);
        this.loadingscreen.parent = this.container!;
        this.loadingscreen.scaling.z = -1;
        this.loadingscreen.scaling.x = -1;
        this.loadingscreen.renderingGroupId = 1;
        this.loadingscreen.parent = this.container!;
        this.loadingscreen.position.z = 0.001;
        this.loadingscreen.renderingGroupId = 1;
        this.loadingscreen.isPickable = false;
        this.loadingscreen.setEnabled(false);

        let advancedTexture = AdvancedDynamicTexture.CreateForMesh(this.loadingscreen, 1024, 1024);
        this.loadingscreen.material!.backFaceCulling = false;

        let guiBase = new Rectangle("Base");
        guiBase.background = "#ACACAC96";
        guiBase.height = "75%";
        guiBase.cornerRadius = 20;
        guiBase.thickness = 0;
        advancedTexture.addControl(guiBase);

        let progressBackground = new Rectangle("progressBackground");
        progressBackground.background = "gray";
        progressBackground.width = "70%";
        progressBackground.height = "200px";
        progressBackground.cornerRadius = 20;
        progressBackground.thickness = 0;
        guiBase.addControl(progressBackground);

        this.loadingProgress = new Rectangle("progress");
        this.loadingProgress.background = "green";
        this.loadingProgress.width = 0;
        this.loadingProgress.thickness = 0;
        this.loadingProgress.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
        progressBackground.addControl(this.loadingProgress);

        var animation = new Animation("animation", "width", 60, Animation.ANIMATIONTYPE_FLOAT);
        animation.setKeys([{frame: 0, value: 0},{frame: 60, value: 1}]);
        this.loadingProgress.animations = [animation];
    }

    public getRightHand(){
        return this.rightHand;
    }

    public getPlane(){
        return this.plane;
    }

    public getFingerCollider(){
        return this.fingerCollider;
    }

    ///////////
    // DEBUG //
    ///////////
    public debugOnDesktop(){
        let scene = Flux360LogicSingleton.getInstance().getGraphicsEngine().getScene()! as Scene;
        scene.activeCamera!.minZ = 0.01;

        this.root!.setEnabled(true);
        this.root!.rotation = new Vector3(0, 2.89, 2.09);
        this.root!.position = new Vector3(-0.1, 0, 0.3);
        this.root!.parent = scene.activeCamera;

        this.ray!.setEnabled(true);
    }
}