import { EngineStore } from '@babylonjs/core/Engines/engineStore';
import { Engine } from '@babylonjs/core/Engines/engine';
import { PhotoDome } from '@babylonjs/core/Helpers/photoDome';
import { WebXRExperienceHelper } from '@babylonjs/core/XR/webXRExperienceHelper';
import { WebXRFeatureName } from '@babylonjs/core/XR/webXRFeaturesManager';
import { WebXRControllerPointerSelection } from '@babylonjs/core/XR/features/WebXRControllerPointerSelection';
import { Scene } from '@babylonjs/core/scene';
import { Vector3 } from "@babylonjs/core/Maths/math.vector";

import { CustomDialogueHandler } from './CustomVRDialogue';
import { Flux360LogicSingleton } from '@/logic/Flux360LogicSingleton';
import type { Panorama } from "flux-360-logic/dist/src/core/situationComponents/Panorama";
import type { SituationComponent } from "flux-360-logic/dist/src/core/situationComponents/SituationComponent";
import { AddVRDebugMenu } from './VRDebug';

export function RegisterVREvents(){
    // DEBUG Menu for VR to see console logs 
    // if(process.env.NODE_ENV == "development")
    //     AddVRDebugMenu();

    // Setup Dialogue (Load Tablet, create Plane etc.)
    Flux360LogicSingleton.getInstance().getGraphicsEngine().getLifeCycleHooks().registerOnVRSupported((vrSupported:boolean) => {
        // Desktop Debug
        // let scene = Flux360LogicSingleton.getInstance().getGraphicsEngine().getScene()! as Scene;
        // let customDialogueHandler = new CustomDialogueHandler();
        // customDialogueHandler.setup(scene);
        // customDialogueHandler.debugOnDesktop();

        if(vrSupported){
            let scene = Flux360LogicSingleton.getInstance().getGraphicsEngine().getScene()! as Scene;
            let customDialogueHandler = new CustomDialogueHandler();
            customDialogueHandler.setup(scene);
        
            // Fix minZ for VR
            Flux360LogicSingleton.getInstance().getGraphicsEngine().getLifeCycleHooks().registerOnAfterVREnter(() => {
                scene.activeCamera!.minZ = 0.01;
            });

            // Remove Pointer Rays from Hands/Controllers
            Flux360LogicSingleton.getInstance().getGraphicsEngine().getLifeCycleHooks().registerOnAfterVRSetup((xrHelper:WebXRExperienceHelper) => {
                const xrPointer = xrHelper.featuresManager.getEnabledFeature(WebXRFeatureName.POINTER_SELECTION) as WebXRControllerPointerSelection;
                xrPointer.displayLaserPointer = false;
                xrPointer.displaySelectionMesh = false;
            });

            // Set Panoramas to Stereo Panorama (TODO: This should happen in logic and should be a config of the mediafile)
            Flux360LogicSingleton.getInstance().getLifeCycleHooks().registerOnAfterSituationComponentCreated((situationComponent:SituationComponent) => {   
                if(situationComponent.getConfig().type == "Panorama"){
                    let panoramaComponent = situationComponent as Panorama;
                    // @ts-ignore
                    panoramaComponent.rootNode.getChildren()[0].imageMode = PhotoDome.MODE_TOPBOTTOM;
                }
            });   

            // Remove medxsmart.de Button & mentimeter for VR
            Flux360LogicSingleton.getInstance().getLifeCycleHooks().registerOnAfterSituationComponentCreated((situationComponent:SituationComponent) => {   
                if(situationComponent.getConfig().type == "Button" && 
                    (
                        situationComponent.getConfig().url == "medxsmart.de" || 
                        situationComponent.getConfig().url == "https://www.mentimeter.com/app/presentation/al57rka2g22g8to4jfkv42xiz3vh5hfo"
                    )
                ){
                    // @ts-ignore
                    situationComponent.rootNode.setEnabled(false);
                }
            });   

            // Workarround for Engine undefined
            Flux360LogicSingleton.getInstance().getGraphicsEngine().getLifeCycleHooks().registerOnEngineCreated((engine:Engine) => {
                EngineStore.Instances.push(engine);
            });
        }
    });
}