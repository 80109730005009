<template>
    <div id="ContentPanel">
        <Frame class="content-frame">

            <!-- Header -->
            <template v-slot:header>
                <ContentPanelHeader/>
            </template>

            <!-- Body -->
            <template v-slot:body>
                <div class="pages-wrapper" :class="type">
                    
                    <!-- Custom Dialogue -->
                    <CustomDialogue v-if="type == 'custom'" :data="data!"/>
                    <DigitaleAnamnese v-if="type == 'digitaleAnamnese'"/>
                    <SelfAssassment v-if="type == 'selfAssassment'"/>
                    <iframe v-if="type == 'video'" :src="url2" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <iframe v-if="type == 'website'" :src="url2" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </template>

        </Frame>
    </div>
</template>

<script setup lang="ts">
// Essentials
import { ref, provide } from 'vue';
import { Flux360LogicSingleton } from '@/logic/Flux360LogicSingleton';
import { Flux360InterfaceSingleton } from '@/interface/Flux360InterfaceSingleton';
import type { SituationComponent } from "flux-360-logic/dist/src/core/situationComponents/SituationComponent";

// Vue Components
import CustomDialogue from '@/components/CustomDialogue/CustomDialogue.vue';
import DigitaleAnamnese from '@/components/DigitaleAnamnese/DigitaleAnamnese.vue';
import SelfAssassment from '@/components/SelfAssassment/SelfAssassment.vue';

// Content Data
import content_json from '@/assets/content/content.json'

let title = ref("");
provide("title",title);

let type = ref("");
let data = ref<PopupData>();
let url2 = ref("");


Flux360InterfaceSingleton.getInstance().getLifeCycleHooks().registerOnContentPanelClosed(()=>{
    type.value = "";
})

// Load PDF/3D Viewer when button is clicked
Flux360LogicSingleton.getInstance().getLifeCycleHooks().registerOnSituationComponentClicked((situationComponent:SituationComponent) => {   
    if(situationComponent.getConfig().type == "Button"){
        let url = situationComponent.getConfig().url;
        
        // return; // Debug

        // Workarround to open Website in new tag
        if(url == "medxsmart.de"){
            window.open("https://www.medxsmart.de/", '_blank')
            Flux360InterfaceSingleton.getInstance().getInterfaceStore().setContentPanelVisible(false);
        }

        if(url == "Digitale Anamnese"){
            title.value = url;
            type.value = "digitaleAnamnese";
        }
        
        if(url == "Self Assassment"){
            title.value = "Praxischeck Digitalisierung";
            type.value = "selfAssassment";
        }

        if(url.includes("http")){
            title.value = "";
            url2.value = url;
            type.value = "website";
        }

        let content = content_json.filter(x => x.id == url)[0] as PopupData
        if(content){
            data.value = content;
            title.value = content.id;
            type.value = "custom";
        }

        setTimeout(() => { document.getElementsByClassName("pages-wrapper")[0]!.scrollTop = 0;}, 0); // Scroll to top when opening a new pop up
    }

    if(situationComponent.getConfig().type == "Video" && (situationComponent.getConfig().url && situationComponent.getConfig().url != "")){
        url2.value = situationComponent.getConfig().url.replace("watch?v=","embed/").replace("&list","?list");
        type.value = "video";
        if(situationComponent.getConfig().modules && situationComponent.getConfig().modules.find((module:any) => module.type.toLowerCase == "tooltip")){
            title.value = situationComponent.getConfig().find((module:any) => module.type.toLowerCase == "tooltip").title;
        }

        Flux360InterfaceSingleton.getInstance().getInterfaceStore().setContentPanelVisible(true);
    }
});
</script>

<style lang="scss">
.Tooltip{
    background-color: rgb(var(--accent-color-1)) !important;
    min-width: auto !important;
}
.Tooltip .label-wrapper{
    text-align: center;
}

#ContentPanel{
    z-index: 2;
    padding: 0px !important;
}

.Frame{
    width: 80% !important;
    height: 80% !important;
    min-width: 800px;
    transform: none !important;
}

.pages-wrapper.custom{
    overflow-y: auto;
    overflow-x: hidden;
}

.pages-wrapper.video, .pages-wrapper.website{
    iframe{
        height:100%;
        width: 100%;
    }
}
</style>