import { name,version } from "../package.json";
import { Component } from 'vue';
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial';

// Logic & Interface
import { Flux360Logic } from 'flux-360-logic';
import { Flux360LogicSingleton } from '@/logic/Flux360LogicSingleton';
import { Flux360Interface } from 'flux-360-interface';
import { Flux360InterfaceSingleton } from '@/interface/Flux360InterfaceSingleton';

// Configs
import logicConfig from '@/configs/config_logic.json';
import interfaceConfig from '@/configs/config_interface.json';

// New Components
import ContentPanel from "./components/ContentPanel.vue";
import { RegisterInteractiveTextWall } from "./components/InteractiveTextWall";
import { RegisterTrophyVideoManagement } from "./components/trophyVideoManagement";

// Custom VR Functions
import { RegisterVREvents } from "./vr/VRHandler";

// Loc Version
console.log("---- Starting " + name + " [" + version + "] ----");
console.log("");

// Create Logic & Interface
let flux360Logic = new Flux360Logic(logicConfig);
let flux360Interface = new Flux360Interface(interfaceConfig);
Flux360LogicSingleton.setInstance(flux360Logic);
Flux360InterfaceSingleton.setInstance(flux360Interface);

// Setup Everything
flux360Interface.setup(flux360Logic, new Map<string, Component>([["ContentPanel",ContentPanel]]),[]);

// Setup Custom VR Functions
RegisterVREvents();

// Register InteractiveTextWall
RegisterInteractiveTextWall();

// Register TrophyVideoManagement
RegisterTrophyVideoManagement();