<template>
    <div class="overlay">
        <div class="clock">
            <div class="hour-hand"></div>
            <div class="minute-hand"></div>
        </div>
        <p>Patient füllt Fragebogen aus..</p>
    </div>
</template>

<script setup lang="ts">
</script>

<style lang="scss">
.overlay {
    --clock-color: rgba(255, 255, 255, 1);
    --clock-thickness: 12px;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2em;

    color: rgba(255, 255, 255, 1);
    font-weight: bold;
}

.clock {
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: transparent;
    border: solid var(--clock-thickness) var(--clock-color);
    opacity: 1;
}

.hour-hand,
.minute-hand {
    position: absolute;
    background-color: #000;
    top: 50%;
    left: 50%;
    height:0;
    width: 0;
    overflow:visible;

    &::before{
        content:'';
        position:absolute;
        background: var(--clock-color);
    }

    &::after{
        content: '';
        position: absolute;
        width: var(--clock-thickness);
        height: var(--clock-thickness);
        top:calc(var(--clock-thickness) / -2);
        left:calc(var(--clock-thickness) / -2);
        border-radius: 50%;
        background: var(--clock-color);
    }
}

.hour-hand {
    &::before{
        width: var(--clock-thickness);
        left: calc(var(--clock-thickness) / -2);
        height: 60px;
    }
    animation: rotate 4320ms linear infinite;
}

.minute-hand {
    &::before{
        width: var(--clock-thickness);
        left: calc(var(--clock-thickness) / -2);
        height: 80px;
    }
    animation: rotate 360ms linear infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg) translate(-50%, -100%);
    }
}
</style>