import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";
import { TextBlock } from '@babylonjs/gui/2D/controls/textBlock';
import { StackPanel } from '@babylonjs/gui/2D/controls/stackPanel';
import { Button } from '@babylonjs/gui/2D/controls/button';
import { RadioButton } from '@babylonjs/gui/2D/controls/radioButton';
import { Control } from '@babylonjs/gui/2D/controls/control';
import { Image as GUIImage } from "@babylonjs/gui/2D/controls/image";

import { Question, calculateResults, getQuestions } from "@/components/SelfAssassment/SelfAssesmentUtil";
import { setActiveTrophyVideo } from "../../components/trophyVideoManagement";

let currentQuestionIndex = 0;

export function constructSelfAssesmentContent(guiBase:Rectangle){
    currentQuestionIndex = 0;
    
    let newBase = new Rectangle("Base");
    newBase.background = "white";
    newBase.cornerRadius = 20;
    newBase.thickness = 0;
    newBase.background = "#53A01B";
    guiBase.addControl(newBase);

    let background = new Rectangle("Background");
    background.width = "85%";
    background.adaptHeightToChildren = true;
    background.cornerRadius = 20;
    background.thickness = 0;
    background.background = "white";
    newBase.addControl(background);

    let padding = new Rectangle("Background");
    padding.adaptHeightToChildren = true;
    padding.setPadding("20px");
    padding.thickness = 0;
    background.addControl(padding);

    let base = new StackPanel("Base");
    base.adaptHeightToChildren = true;
    padding.addControl(base);

    constructStartPage(base);
}

function constructStartPage(guiBase:StackPanel){
    let headline = new TextBlock("Headline", "Der Praxischeck Digitalisierung");
    headline.fontFamily = "OpenSansRegular";
    headline.fontWeight = "bold";
    headline.fontSize = "34px";
    headline.resizeToFit = true;
    headline.textWrapping = true;
    headline.paddingBottom = 25;
    guiBase.addControl(headline);

    let text = new TextBlock("Headline", "Durch diesen Fragebogen können Sie den Digitalisierungsstand Ihrer Praxis ganz leicht einschätzen. Beantworten Sie einfach die folgenden elf Fragen zu den aktuellen Themen der Digitalisierung einer Praxis.");
    text.fontFamily = "OpenSansRegular";
    text.fontSize = "24px";
    text.resizeToFit = true;
    text.textWrapping = true;
    text.paddingBottom = 30;
    guiBase.addControl(text);

    let button = Button.CreateSimpleButton("Button", "Weiter");
    button.textBlock!.fontSize = "30px";
    button.height = "100px";
    button.width = "220px";
    button.color = "white";
    button.background = "#38821B";
    button.onPointerClickObservable.add(() => {
        constructQuestionPage(guiBase, currentQuestionIndex);
    });
    guiBase.addControl(button);
}

function constructQuestionPage(guiBase:StackPanel, index:number){
    currentQuestionIndex++;

    guiBase!.children.forEach(child => { setTimeout(() => { child.dispose(); }, 0); }); // Cleanup dialogue!

    let question = getQuestions()[index];

    let headline = new TextBlock("Headline", "Test - Frage " + currentQuestionIndex);
    headline.fontFamily = "OpenSansRegular";
    headline.fontWeight = "bold";
    headline.fontSize = "34px";
    headline.resizeToFit = true;
    headline.textWrapping = true;
    headline.paddingBottom = 25;
    guiBase.addControl(headline);

    let text = new TextBlock("Headline", question.question);
    text.fontFamily = "OpenSansRegular";
    text.fontSize = "26px";
    text.resizeToFit = true;
    text.textWrapping = true;
    text.paddingBottom = 30;
    guiBase.addControl(text);

    createAnswerElement(guiBase, question, 0);
    createAnswerElement(guiBase, question, 1);
    createAnswerElement(guiBase, question, 2);
    createAnswerElement(guiBase, question, 3);

    let button = Button.CreateSimpleButton("Button", "Weiter");
    button.textBlock!.fontSize = "30px";
    button.height = "130px";
    button.width = "260px";
    button.color = "white";
    button.paddingTop = 20;
    button.background = "#38821B";
    button.onPointerClickObservable.add(() => {
        if(currentQuestionIndex == 11){
            createResultElement(guiBase);
        }else{
            constructQuestionPage(guiBase, currentQuestionIndex);
        }
    });
    guiBase.addControl(button);
}

function createAnswerElement(guiBase:StackPanel, question:Question, answerID:number){
    let base = new StackPanel();
    base.height = "90px";
    base.paddingBottom = "10px";
    base.isVertical = false;
    base.background = "#F3F9F1";

    base.onPointerClickObservable.add(() => {
        button.isChecked = true;
        guiBase.children.forEach((child,i) => { if(i < 6)(child as StackPanel).background = "#F3F9F1"; });
        base.background = "#DCEDD3";
    });
    guiBase.addControl(base);

    let button = new RadioButton();
    button.width = "90px"; // 30px Button height + 20px Padding Right & 40px Padding Left
    button.height = "30px";
    button.color = "green";
    button.background = "white";   
    button.paddingRight = "20px"
    button.paddingLeft = "40px";  
    button.onIsCheckedChangedObservable.add((state) => {
        if(state)
            question.answerValue = answerID;
    }); 
    base.addControl(button);

    let answerText = "Nein";
    if(answerID == 1)
        answerText = "Teilweise";
    if(answerID == 2)
        answerText = "Ja";
    if(answerID == 3)
        answerText = "Kann ich nicht beantworten";

    let text = new TextBlock("Text", answerText);
    text.paddingRight = "40";
    text.fontFamily = "OpenSansRegular";
    text.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    text.width = "400px";
    text.fontSize = "26px";
    base.addControl(text);
}

function createResultElement(guiBase:StackPanel){
    guiBase!.children.forEach(child => { setTimeout(() => { child.dispose(); }, 0); }); // Cleanup dialogue!

    let headline = new TextBlock("Headline", "Ergebnis");
    headline.fontFamily = "OpenSansRegular";
    headline.fontWeight = "bold";
    headline.fontSize = "34px";
    headline.resizeToFit = true;
    headline.textWrapping = true;
    headline.paddingBottom = 15;
    guiBase.addControl(headline);

    let text = new TextBlock("Text", "Vielen Dank für Ihre Teilnahme am „Praxischeck Digitalisierung“!");
    text.fontFamily = "OpenSansRegular";
    text.fontSize = "24px";
    text.resizeToFit = true;
    text.textWrapping = true;
    text.paddingBottom = 10;
    guiBase.addControl(text);

    let result = calculateResults();

    let imagePath = "/src/assets/content/img/trophies/";
    if(result.stars == 5) imagePath += "trophy-5star.jpg";
    if(result.stars == 4) imagePath += "trophy-4star.jpg";
    if(result.stars == 3) imagePath += "trophy-3star.jpg";
    if(result.stars == 2) imagePath += "trophy-2star.jpg";
    if(result.stars == 1) imagePath += "trophy-1star.jpg";
    let image = new GUIImage("", imagePath);
    image.paddingBottom = 10;
    image.width = "250px";
    image.height = "250px";
    image.stretch = GUIImage.STRETCH_UNIFORM;
    guiBase.addControl(image);

    let starBase = new StackPanel("Base");
    starBase.adaptHeightToChildren = true;
    starBase.isVertical = false;
    starBase.paddingBottom = 10;
    guiBase.addControl(starBase);

    for (let i = 0; i < result.stars!; i++) {
        let star = new GUIImage("", "/src/assets/content/img/star.png");
        star.width = "80px";
        star.height = "80px";
        star.stretch = GUIImage.STRETCH_UNIFORM;
        starBase.addControl(star);
    }

    let scoreText = new TextBlock("Text", 'Herzlichen Glückwunsch! Hier ist Ihr Pokal…');
    scoreText.fontFamily = "OpenSansRegular";
    scoreText.fontStyle = "Italic";
    scoreText.fontSize = "24px";
    scoreText.resizeToFit = true;
    scoreText.textWrapping = true;
    scoreText.paddingBottom = 30;
    guiBase.addControl(scoreText);
    

    let button = Button.CreateSimpleButton("Button", "Abschließen");
    button.textBlock!.fontSize = "30px";
    button.height = "100px";
    button.width = "240px";
    button.color = "white";
    button.background = "#38821B";
    button.onPointerClickObservable.add(() => {
        guiBase.parent!.parent!.parent!.background = "white";
        guiBase.parent!.parent!.dispose();
        
        if(result.stars == 5) setActiveTrophyVideo(29);
        if(result.stars == 4) setActiveTrophyVideo(32);
        if(result.stars == 3) setActiveTrophyVideo(33);
        if(result.stars == 2) setActiveTrophyVideo(34);
        if(result.stars == 1) setActiveTrophyVideo(35);
    });
    guiBase.addControl(button);
}
