<template>
    <div id="patienten-list">
        <div class="patient" v-for="patient in patienten" :class="{'clickable': patient.forename == 'Erika'}" @click="choose(patient)">
            <img src="@/assets/content/img/profilepic.svg" alt="">
            <div class="info-wrapper">
                <div class="name">{{ patient.lastname }}, {{ patient.forename }} <span class="info-badge orange" v-if="patient.forename == 'Erika'">Unvollständig</span></div>
                <div class="birth">geb. {{ patient.birth }}</div>
                <div class="address">{{ patient.address_town }}, {{ patient.address_street }}</div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {ref} from "vue";

const props = defineProps({
    nextStep:{
        type:Function,
        required: true
    }
})

function choose(patient:any){
    if(patient.forename == 'Erika')
        props.nextStep();
}

const patienten = ref([
    {forename:"Hans",lastname:"Müller",birth:"23.10.1973",address_town:"23456 Musterstadt",address_street:"Beispielstraße 2"},
    {forename:"Thomas",lastname:"Schmidt",birth:"14.04.1984",address_town:"23456 Musterstadt",address_street:"Beispielstraße 4"},
    {forename:"Sabine",lastname:"Schmidt",birth:"02.05.1986",address_town:"23456 Musterstadt",address_street:"Beispielstraße 4"},
    {forename:"Erika",lastname:"Mustermann",birth:"--.--.----",address_town:"-",address_street:"-"},
    {forename:"Anna",lastname:"Meier",birth:"09.11.1999",address_town:"23456 Musterstadt",address_street:"Beispielweg 16"},
    {forename:"Otto",lastname:"Schuster",birth:"30.01.1960",address_town:"23456 Musterstadt",address_street:"Beispielweg 4"},
    {forename:"Max",lastname:"Mustermann",birth:"16.09.1966",address_town:"23456 Musterstadt",address_street:"Beispielstraße 1"}
]) 
</script>

<style lang="scss" scoped>
#patienten-list{
    $color-rgb: rgb(85, 165, 28);

    display: flex;
    flex-direction: column;

    .patient{
        display: flex;
        flex-direction: row;
        padding: 8px;
        gap: 8px;
        cursor: not-allowed;
        
        &.clickable{
            cursor: pointer;
        }

        &:hover{
            background: rgba($color-rgb, 0.1);
        }

        img{
            height: 80px;
        }

        .name{
            font-weight: bold;
        }

        .birth, .address{
            opacity: 0.66;
        }

        &:not(:last-child){
            border-bottom: 1px solid rgba(0,0,0,0.1);
        }
    }
}
</style>